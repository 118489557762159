/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useAuth} from '../modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {useLang, setLanguage} from '../../_metronic/i18n/Metronici18n'
import {Languages} from './Languages'


const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'jp',
    name: 'Japanese',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  }
]


const HeaderUserMenu: FC = () => {
  const { user, logout } = useAuth()
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  const onLogout = () => {
    logout()
    window.location.href = '/auth'
  }


  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Profile' src={toAbsoluteUrl('/media/avatars/blank.png')} />
            {/*
            <span className='symbol-label bg-light-info'>
              <KTSVG
                path='/media/icons/duotune/finance/fin006.svg'
                className='svg-icon-2x svg-icon-info'
              />
            </span>
            */}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>


      {/*
        <div className='menu-item px-5'>
          <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
            My Profile
          </Link>
        </div>

        <div className='menu-item px-5'>
          <a href='#' className='menu-link px-5'>
            <span className='menu-text'>My Projects</span>
            <span className='menu-badge'>
              <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
            </span>
          </a>
        </div>

        <div
          className='menu-item px-5'
          data-kt-menu-trigger='hover'
          data-kt-menu-placement='left-start'
          data-kt-menu-flip='bottom'
        >
          <a href='#' className='menu-link px-5'>
            <span className='menu-title'>My Subscription</span>
            <span className='menu-arrow'></span>
          </a>

          <div className='menu-sub menu-sub-dropdown w-175px py-4'>
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5'>
                Referrals
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5'>
                Billing
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-5'>
                Payments
              </a>
            </div>

            <div className='menu-item px-3'>
              <a href='#' className='menu-link d-flex flex-stack px-5'>
                Statements
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='View your statements'
                ></i>
              </a>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-3'>
              <div className='menu-content px-3'>
                <label className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input w-30px h-20px'
                    type='checkbox'
                    value='1'
                    defaultChecked={true}
                    name='notifications'
                  />
                  <span className='form-check-label text-muted fs-7'>Notifications</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='menu-item px-5'>
          <a href='#' className='menu-link px-5'>
            My Statements
          </a>
        </div>

        <div className='separator my-2'></div>
      */}


      <Languages />
      {/*
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title position-relative'>
            Language
            <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
              {currentLanguage?.name}{' '}
              <img
                className='w-15px h-15px rounded-1 ms-2'
                src={currentLanguage?.flag}
                alt='metronic'
              />
            </span>
          </span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          {languages.map((l) => (
            <div
              className='menu-item px-3'
              key={l.lang}
              onClick={() => {
                setLanguage(l.lang)
              }}
            >
              <a
                href='#'
                className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
              >
                <span className='symbol symbol-20px me-4'>
                  <img className='rounded-1' src={l.flag} alt='metronic' />
                </span>
                {l.name}
              </a>
            </div>
          ))}
        </div>
      </div>
      */}


      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}

      <div className='menu-item px-5'>
        <a onClick={onLogout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
