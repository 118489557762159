/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import { endOfMonth } from 'date-fns'; 

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string,
  dates : Array<number>,
  spot : Array<number>,
  sub : Array<number>,
  quotes : Array<number>
  month : number,
  year : number
}

const DashboardWidget1: React.FC<Props> = ({className, chartColor, chartHeight, strokeColor , dates , spot, sub , quotes , month , year}) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  const { user } = useAuth()
  const intl = useIntl()

  let filledDates :any = [];
  if(!dates?.length){
    const lastDayOfMonth = endOfMonth(new Date(year, month - 1)); // Getting the last day of the given month
    filledDates = Array.from({ length: lastDayOfMonth.getDate() }, (_, index) => index + 1); // Creating an array with days from 1 to lastDayOfMonth
  } else {
    filledDates = dates
  }

  const zeroFilledArray = Array(filledDates.length).fill(0);
  const filledSpot = spot?.length ? spot : zeroFilledArray;
  const filledSub = sub?.length ? sub : zeroFilledArray;
  const filledQuotes = quotes?.length ? quotes : zeroFilledArray;

  useEffect(() => {
    if (!chartRef.current || !dates || !spot || !sub || !quotes || !month || !year) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, strokeColor , filledDates , filledSpot , filledSub , filledQuotes , month , year)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef,dates , spot, sub])

  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className='card-title fw-bolder text-white'>{intl.formatMessage({id: 'DASHBOARD.STATISTICS'})}</h3>
        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      <div className='card-body p-0'>
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>

        {/* <div className='card-p mt-n20 position-relative'>
          <div className='row g-0'>
            <Link to="/" className='col me-7 mb-7'>
              <div className='col bg-light-warning px-6 py-8 rounded-2'>
                <KTSVG
                  path='/media/icons/duotune/general/gen032.svg'
                  className='svg-icon-3x svg-icon-warning d-block my-2'
                />
                <p className='text-warning fw-bold fs-6'>
                  {intl.formatMessage({id: 'DASHBOARD.MONTHLY_SALES'})}
                </p>
              </div>
            </Link>

            {user?.role === 'admin' ? (
              <Link to="/add-company" className='col mb-7'>
                <div className='col bg-light-primary px-6 py-8 rounded-2'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr075.svg'
                    className='svg-icon-3x svg-icon-primary d-block my-2'
                  />
                  <p className='text-primary fw-bold fs-6'>
                    {intl.formatMessage({id: 'DASHBOARD.NEW_COMPANY'})}
                  </p>
                </div>
              </Link>
            ) : (
              <Link to="/" className='col mb-7'>
                <div className='col bg-light-primary px-6 py-8 rounded-2'>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr075.svg'
                    className='svg-icon-3x svg-icon-primary d-block my-2'
                  />
                  <p className='text-primary fw-bold fs-6'>
                    {intl.formatMessage({id: 'DASHBOARD.NEW_USER'})}
                  </p>
                </div>
              </Link>
            )}

          </div>

          <div className='row g-0'>
            <Link to="/quotations" className='col me-7'>
              <div className='bg-light-danger px-6 py-8 rounded-2'>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs027.svg'
                  className='svg-icon-3x svg-icon-danger d-block my-2'
                />
                <p className='text-danger fw-bold fs-6 mt-2'>
                  {intl.formatMessage({id: 'DASHBOARD.QUOTATIONS'})}
                </p>
              </div>
            </Link>

            <Link to="/requests" className='col'>
              <div className='bg-light-success px-6 py-8 rounded-2'>
                <KTSVG
                  path='/media/icons/duotune/communication/com010.svg'
                  className='svg-icon-3x svg-icon-success d-block my-2'
                />
                <p className='text-success fw-bold fs-6 mt-2'>
                  {intl.formatMessage({id: 'DASHBOARD.REQUESTS'})}
                </p>
              </div>
            </Link>

          </div>
        </div> */}
      </div>
    </div>
  )
}

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string ,
  filledDates : Array<number>,
  filledSpot : Array<number>,
  filledSub : Array<number>,
  filledQuotes : Array<number>,
  month : Number,
  year : Number
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const color = getCSSVariableValue('--bs-' + chartColor)

  const colors = ['#000', '#f7b544', '#6f3deb']
  const colorsFaded = ['rgba(0,0,0,.25)', 'rgba(247,181,68,.25)', 'rgba(111,61,235,.25)']


  return {
    series: [
      // {
      //   name: '# Companies',
      //   data: [15, 18, 23, 26, 22, 30, 34],
      // },
      {
        name: 'Spot Requests ',
        data: filledSpot,
      },
      {
        name: 'Subscription Requests',
        data: filledSub,
      },
      {
        name: 'Quotes',
        data: filledQuotes,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined
      },
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 0.2,
      colors: colors,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: colors,
    },
    xaxis: {
      categories: filledDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: borderColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: function(max) {
        return max + 2
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      marker: {
        show: false,
      },
      custom: function({ series, seriesIndex, dataPointIndex, w }) {

        const seriesNames = w.config.series.map(line => line.name)
        const data = series.map((line, index) => line[dataPointIndex])
       

        const dataFormatter = (value, name) => {

          // if (name === 'Company Sales') {
          //   return '¥' + value + 'k'
          // }
          return value
        }

        let dateObjs :any= [];

        // Loop through the dates array and convert each date to a full date string
        filledDates.forEach(date => {
          const dateString = `${date}/${month}/${year}`;
          dateObjs.push(dateString);
        });

        let points = ''
        data.forEach((dataPoint, index) => {
          points += `<div
            class='d-flex justify-content-between align-items-center p-3 ${index < data.length - 1 ? 'border border-bottom' : ''}'
          >
            <p class='fw-bold fs-7 mb-0 me-10' style='color: ${colors[index]};'>${seriesNames[index]}</p>
            <p class='mb-0'>${dataPoint}</p>
          </div>`
        })
        return `<div class='m-0 mb-3 p-0'>${points} <span class='fw-bold fs-7 m-1 p-2 border border-bottom'>Date : ${dateObjs[dataPointIndex]}</span></div>`;

      }
    },
    colors: colors,
    markers: {
      colors: colors,
      strokeColors: colors,
      strokeWidth: 3,
    },
  }
}

export {DashboardWidget1}
