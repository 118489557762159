


export function formatStatus(request: any, intl: any) {
  switch (request?.status) {
    case 1:
      if (request?.pickup?.length) {
        return {
          color: 'light-info',
          text: intl.formatMessage({id: 'COMMON.STATUS.COLLECTED'})
        }
      }
      else {
        return {
          color: 'light-success',
          text: intl.formatMessage({id: 'COMMON.STATUS.ACCEPTED'})
        }
      }
    case 2:
      return {
        color: 'light',
        text: intl.formatMessage({id: 'COMMON.STATUS.COMPLETE'})
      }
    case 3:
      return {
        color: 'light-danger',
        text: intl.formatMessage({id: 'COMMON.STATUS.CANCELLED'})
      }
    case 4:
      return {
        color: 'light-danger',
        text: intl.formatMessage({id: 'COMMON.STATUS.CANCELLED'})
      }
    default:
      return {
        color: 'light-warning',
        text: intl.formatMessage({id: 'COMMON.STATUS.OPEN'})
      }
  }
}



export function formatDateTime(d: string | undefined) {
  const date = new Date(d || Date.now())
  return `${date.getFullYear()}年 ${date.getMonth() + 1}月 ${date.getDate()}日, ${date.toLocaleString('en-US', {
    timeStyle: 'short',
    hourCycle: 'h24'
  })}`
}

export function formatDateOnly(d: string | undefined) {
  const date = new Date(d || Date.now())
  return `${date.getFullYear()}年 ${date.getMonth() + 1}月 ${date.getDate()}日`
}

export function formatDayOfMonthOnly(d: string | undefined) {
  const date = new Date(d || Date.now())
  return `${date.getDate()}日`
}

export function formatPostalCode(postalCode: string | undefined) {
  if (!postalCode || postalCode?.length < 7) {
    return '--'
  }

  return `〒${postalCode.substring(0, 3)}-${postalCode.substring(3, 7)}`
}

export function addCommas(amount : any) {
  let amountStr = amount?.toString();
  let amountLen = amountStr?.length;
    if (amountLen < 4) {
      return amountStr;
    }
  let commaIndex = amountLen % 3;
  let result = amountStr?.slice(0, commaIndex);
   while (commaIndex < amountLen) {
     if (result !== "") {
       result += ",";
     }
     result += amountStr?.slice(commaIndex, commaIndex + 3);
     commaIndex += 3;
   }
  
  return result;
}

export function formatMonth (month : number) {
  switch(month){
      case 1:
          return '1月';
      case 2:
          return '2月';
      case 3:
          return '3月';
      case 4:
          return '4月';
      case 5:
          return '5月';
      case 6:
          return '6月';
      case 7:
          return '7月';
      case 8:
          return '8月';
      case 9:
          return '9月';
      case 10:
          return '10月';
      case 11:
          return '11月';
      case 12:
          return '12月';

  }
}


export function formatPhoneNumber (num: string){
  if (!num || !num.trim()) return '(電話番号はありません)';
  let string = num;

  if (num.length > 4) {
      string = string.slice(0, -4) + '-' + string.slice(-4);
  }
  if (num.length > 8) {
      string = string.slice(0, -9) + '-' + string.slice(-9);
  }
  if (num.length < 13) {
      string = '0' + string;
  }

  return string;
};





export function formatPaymentMethod(payment_mode: string, intl: any) {
  switch (payment_mode) {
    case 'card':
      return {
        text: intl.formatMessage({id: 'COMMON.PAYMENT.CARD'})
      }
    case 'cash':
      return {
        text: intl.formatMessage({id: 'COMMON.PAYMENT.CASH'})
      }
    default:
      return {
        text: '--'
      }
  }
}
