import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {api} from '../../helpers/api_helper'
import {useIntl} from 'react-intl'


export function PaymentSuccess() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const { mode } = useParams()

  const intl = useIntl()

  return (
    <>
      <div className='card w-100 mw-400px mx-auto mt-20'>
        <div className='card-body d-flex flex-column'>
          <div className='text-center mt-5'>
            <h1 className='text-success mb-7'>{intl.formatMessage({id: 'INVOICE.SUCCESS.TITLE'})}</h1>
            <p className='text-gray-600 mb-10'>{intl.formatMessage({id: 'INVOICE.SUCCESS.DESCRIPTION'})}</p>
            {mode == 'cash' ? (
              <>
                <p className='fs-5'>{intl.formatMessage({id: 'INVOICE.PAYMENT_METHOD'})}: <span className='fw-bolder'>{intl.formatMessage({id: 'INVOICE.CASH'})}</span></p>
                <p className='fw-bold'>{intl.formatMessage({id: 'INVOICE.SUCCESS.CASH.TEXT_1'})} <span className='text-primary'>{intl.formatMessage({id: 'INVOICE.SUCCESS.CASH.TEXT_2'})}</span></p>
              </>
            ) : (
              <>
                <p className='fs-5'>{intl.formatMessage({id: 'INVOICE.PAYMENT_METHOD'})}: <span className='fw-bolder'>{intl.formatMessage({id: 'INVOICE.CARD'})}</span></p>
                <p className='fw-bold text-primary'>{intl.formatMessage({id: 'INVOICE.SUCCESS.CARD.TEXT_1'})}</p>
              </>
            )}
            <p className='mt-10'>{intl.formatMessage({id: 'INVOICE.SUCCESS.CLOSE'})}</p>
          </div>
        </div>
      </div>
    </>
  )
}
