/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useRef, useEffect, useState ,FC} from 'react'
import {Link} from 'react-router-dom'
import {useLayout} from '../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl, checkIsActive} from '../../_metronic/helpers'
import {AsideMenuMain} from '../../_metronic/layout/components/aside/AsideMenuMain'
import {AsideMenuItem} from '../../_metronic/layout/components/aside/AsideMenuItem'
import {AsideMenuItemWithSub} from '../../_metronic/layout/components/aside/AsideMenuItemWithSub'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../_metronic/assets/ts/components'
import {useAuth} from '../modules/auth/core/Auth'
import { api } from '../helpers/api_helper'



const SideMenu: FC = () => {
  const {config, classes} = useLayout()
  const {aside} = config
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()
  const [companyId , setCompanyId] = useState<string | undefined>('')
  const intl = useIntl()

  const showTemplate = false

  const { user } = useAuth()
  const isAdmin: boolean = user?.role == 'admin'


  const getData = async () => {
    try{
      const response = await api('POST', '/company/details', )
      if(response){
        setCompanyId(response?.data?.data?._id)
      }
    }catch(err :any){
    }
  }

  useEffect(()=>{
    if(!isAdmin){
      getData()
    }
  },[])


  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {aside.theme === 'dark' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='logo'
              style={{ height: '40px' }}
              src={toAbsoluteUrl('/media/logos/dasutalk_logo.png')}
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='logo'
              style={{ height: '40px' }}
              src={toAbsoluteUrl('/media/logos/dasutalk_logo.png')}
            />
          </Link>
        )}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
      </div>

      <div className='aside-menu flex-column-fluid'>
        <div
          id='kt_aside_menu_wrapper'
          ref={scrollRef}
          className='hover-scroll-overlay-y my-5 my-lg-5'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-height='auto'
          data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
          data-kt-scroll-wrappers='#kt_aside_menu'
          data-kt-scroll-offset='0'
        >
          <div
            id='#kt_aside_menu'
            data-kt-menu='true'
            className={clsx(
              'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
              classes.asideMenu.join(' ')
            )}
          >


            <div className="my-2 py-2"></div>

            {/*
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pages</span>
              </div>
            </div>
            */}



            <div className='menu-item'>
              <Link to='/dashboard' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/dashboard') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/layouts/lay006.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</span>
              </Link>
            </div>

            {isAdmin && (
              <div className='menu-item'>
                <Link to='/companies' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/companies') })}>
                  <span className='menu-icon'>
                    <KTSVG path='/media/icons/duotune/electronics/elc009.svg' className='svg-icon-2 menu-item-svg' />
                  </span>
                  <span className='menu-title'>{intl.formatMessage({id: 'MENU.COMPANIES'})}</span>
                </Link>

                <Link to='/users' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/users') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.USERS'})}</span>
              </Link>
              </div>
            )}

           {!isAdmin && (
              <div className='menu-item'>
                <Link to={`/myCompany/${companyId}`} className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/myCompany') })}>
                  <span className='menu-icon'>
                    <KTSVG path='/media/icons/duotune/electronics/elc009.svg' className='svg-icon-2 menu-item-svg' />
                  </span>
                  <span className='menu-title'>{intl.formatMessage({id: 'MENU.MY_COMPANIES'})}</span>
                </Link>
              </div>
            )}

            {/* <div className='menu-item'>
              <Link to='/users' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/users') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/communication/com006.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.USERS'})}</span>
              </Link>
            </div> */}

            <div className='menu-item'>
              <Link to='/requests' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/requests') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.TOTAL_REQUESTS'})}</span>
              </Link>
            </div>

            {/*
            <div className='menu-item'>
              <Link to='/subscriptions' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/subscriptions') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/communication/com002.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.SUBSCRIPTIONS'})}</span>
              </Link>
            </div>
            */}

            <div className='menu-item'>
              <Link to='/quotations' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/quotations') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.QUOTATIONS'})}</span>
              </Link>
            </div>

                <div className='menu-item'>
                  <Link to='/invoices' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/invoices') })}>
                    <span className='menu-icon'>
                      <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2 menu-item-svg' />
                    </span>
                    <span className='menu-title'>{intl.formatMessage({id: 'MENU.INVOICES'})}</span>
                  </Link>
                </div>

            <div className='menu-item'>
              <Link to='/reports' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/reports') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.REPORTS'})}</span>
              </Link>
            </div>

            <div className='menu-item'>
              <Link to='/reviews' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/reviews') })}>
                <span className='menu-icon'>
                  <KTSVG path='/media/icons/duotune/finance/fin002.svg' className='svg-icon-2 menu-item-svg' />
                </span>
                <span className='menu-title'>{intl.formatMessage({id: 'MENU.REVIEWS'})}</span>
              </Link>
            </div>


            {/*
            {isAdmin && (
              <div
                className={clsx('menu-item', {'here show': checkIsActive(pathname, '/settings')}, 'menu-accordion')}
                data-kt-menu-trigger='click'
              >
                <span className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/settings') })}>
                  <span className='menu-icon'>
                    <KTSVG path='/media/icons/duotune/finance/fin007.svg' className='svg-icon-2 menu-item-svg' />
                  </span>
                  <span className='menu-title'>{intl.formatMessage({id: 'MENU.SETTINGS'})}</span>
                  <span className='menu-arrow'></span>
                </span>

                <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': checkIsActive(pathname, '/payments')})}>

                  <div className='menu-item'>
                    <Link to='/settings/services' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/settings/services') })}>
                      <span className='menu-icon'>
                        <KTSVG path='/media/icons/duotune/finance/fin001.svg' className='svg-icon-2 menu-item-svg' />
                      </span>
                      <span className='menu-title'>{intl.formatMessage({id: 'MENU.SERVICES'})}</span>
                    </Link>
                  </div>

                  <div className='menu-item'>
                    <Link to='/settings/categories' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/settings/categories') })}>
                      <span className='menu-icon'>
                        <KTSVG path='/media/icons/duotune/finance/fin008.svg' className='svg-icon-2 menu-item-svg' />
                      </span>
                      <span className='menu-title'>{intl.formatMessage({id: 'MENU.CATEGORIES'})}</span>
                    </Link>
                  </div>

                  <div className='menu-item'>
                    <Link to='/settings/items' className={clsx('menu-link without-sub', { active: checkIsActive(pathname, '/settings/items') })}>
                      <span className='menu-icon'>
                        <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-2 menu-item-svg' />
                      </span>
                      <span className='menu-title'>{intl.formatMessage({id: 'MENU.ITEMS'})}</span>
                    </Link>
                  </div>

                </div>
              </div>
            )}
            */}



            {showTemplate && (
              <>
                <AsideMenuItem
                  to='/dashboard'
                  icon='/media/icons/duotune/art/art002.svg'
                  title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                  fontIcon='bi-app-indicator'
                />
                <AsideMenuItem
                  to='/builder'
                  icon='/media/icons/duotune/general/gen019.svg'
                  title='Layout Builder'
                  fontIcon='bi-layers'
                />
                <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
                  </div>
                </div>
                <AsideMenuItemWithSub
                  to='/crafted/pages'
                  title='Pages'
                  fontIcon='bi-archive'
                  icon='/media/icons/duotune/general/gen022.svg'
                >
                  <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
                    <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
                    <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
                    <AsideMenuItem
                      to='/crafted/pages/profile/connections'
                      title='Connections'
                      hasBullet={true}
                    />
                  </AsideMenuItemWithSub>

                  <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
                    <AsideMenuItem
                      to='/crafted/pages/wizards/horizontal'
                      title='Horizontal'
                      hasBullet={true}
                    />
                    <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
                  </AsideMenuItemWithSub>
                </AsideMenuItemWithSub>
                <AsideMenuItemWithSub
                  to='/crafted/accounts'
                  title='Accounts'
                  icon='/media/icons/duotune/communication/com006.svg'
                  fontIcon='bi-person'
                >
                  <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
                  <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
                </AsideMenuItemWithSub>
                <AsideMenuItemWithSub
                  to='/error'
                  title='Errors'
                  fontIcon='bi-sticky'
                  icon='/media/icons/duotune/general/gen040.svg'
                >
                  <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
                  <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
                </AsideMenuItemWithSub>
                <AsideMenuItemWithSub
                  to='/crafted/widgets'
                  title='Widgets'
                  icon='/media/icons/duotune/general/gen025.svg'
                  fontIcon='bi-layers'
                >
                  <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
                  <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
                  <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
                  <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
                  <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
                  <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
                </AsideMenuItemWithSub>
                <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
                  </div>
                </div>
                <AsideMenuItemWithSub
                  to='/apps/chat'
                  title='Chat'
                  fontIcon='bi-chat-left'
                  icon='/media/icons/duotune/communication/com012.svg'
                >
                  <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
                  <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
                  <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
                </AsideMenuItemWithSub>
                <AsideMenuItem
                  to='/apps/user-management/users'
                  icon='/media/icons/duotune/general/gen051.svg'
                  title='User management'
                  fontIcon='bi-layers'
                />
                <div className='menu-item'>
                  <div className='menu-content'>
                    <div className='separator mx-1 my-4'></div>
                  </div>
                </div>
                <div className='menu-item'>
                  <a
                    target='_blank'
                    className='menu-link'
                    href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
                  >
                    <span className='menu-icon'>
                      <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
                    </span>
                    <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
                  </a>
                </div>
              </>
            )}

          </div>
        </div>
      </div>

      {showTemplate && (
        <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
          <a
            target='_blank'
            className='btn btn-custom btn-primary w-100'
            href={process.env.REACT_APP_PREVIEW_DOCS_URL}
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Check out the complete documentation with over 100 components'
          >
            <span className='btn-label'>Docs & Components</span>
            <span className='svg-icon btn-icon svg-icon-2'>
              <KTSVG path='/media/icons/duotune/general/gen005.svg' />
            </span>
          </a>
        </div>
      )}

    </div>
  )
}

export {SideMenu}
