import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/template/dashboard/DashboardWrapper'
import {Dashboard} from '../pages/dashboard/Dashboard'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

const PrivateRoutes = (props: any) => {
  const BuilderPageWrapper = lazy(() => import('../pages/template/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const Requests = lazy(() => import('../pages/requests/Requests'))
  const RequestDetails = lazy(() => import('../pages/requests/RequestDetails'))
  const AddQuote = lazy(() => import('../pages/requests/AddQuote'))
  const Invoices = lazy(() => import('../pages/invoices/InvoiceDetails'))
  const InvoiceDetails = lazy(()=> import('../pages/invoices/InvoiceView'))
  const ReportsPage = lazy(() => import('../pages/reports/ReportsPage'))


  const Quotations = lazy(() => import('../pages/quotations/Quotations'))
  const QuotationDetails = lazy(() => import('../pages/quotations/QuotationDetails'))

  const Reviews = lazy(() => import('../pages/reviews/Reviews'))
  const ReviewDetails = lazy(() => import('../pages/reviews/ReviewDetails'))

  const Companies = lazy(() => import('../pages/companies/Companies'))
  const CompanyDetails = lazy(() => import('../pages/companies/CompanyDetails'))
  const AddCompany = lazy(() => import('../pages/companies/AddCompany'))
  const EditCompany = lazy(() => import('../pages/companies/EditCompany'))

  const Users = lazy(() => import('../pages/users/Users'))


  const Services = lazy(() => import('../pages/settings/services/Services'))
  const AddService = lazy(() => import('../pages/settings/services/AddService'))

  const Categories = lazy(() => import('../pages/settings/categories/Categories'))
  const AddCategory = lazy(() => import('../pages/settings/categories/AddCategory'))

  const Items = lazy(() => import('../pages/settings/items/Items'))
  const AddItem = lazy(() => import('../pages/settings/items/AddItem'))

  const { user } = props


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='template/dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='invoices' element={<Invoices />} />
        <Route path='reports' element={<ReportsPage />} />


        <Route path='dashboard' element={<Dashboard />} />


        <Route
          path='requests/*'
          element={
            <SuspensedView>
              <Requests />
            </SuspensedView>
          }
        />
        <Route
          path='request/:id'
          element={
            <SuspensedView>
              <RequestDetails />
            </SuspensedView>
          }
        />
        <Route
          path='request/:id/add-quote'
          element={
            <SuspensedView>
              <AddQuote />
            </SuspensedView>
          }
        />


        <Route
          path='quotations/*'
          element={
            <SuspensedView>
              <Quotations />
            </SuspensedView>
          }
        />

        <Route
          path='quotation/:id'
          element={
            <SuspensedView>
              <QuotationDetails />
            </SuspensedView>
          }
        />

         <Route
          path='myCompany/:id'
          element={
            <SuspensedView>
              <CompanyDetails />
            </SuspensedView>
          }
        />

        <Route
          path='reviews/*'
          element={
            <SuspensedView>
              <Reviews />
            </SuspensedView>
          }
        />

        <Route
          path='review/:id'
          element={
            <SuspensedView>
              <ReviewDetails />
            </SuspensedView>
          }
        />

          <Route
          path='invoice/:id'
          element={
            <SuspensedView>
              <InvoiceDetails />
            </SuspensedView>
          }
        />

        <Route
          path='users/*'
          element={
            <SuspensedView>
              <Users />
            </SuspensedView>
          }
        />

        {user?.role === 'admin' && (
          <Route
            path='companies/*'
            element={
              <SuspensedView>
                <Companies />
              </SuspensedView>
            }
          />
        )}
        {user?.role === 'admin' && (
          <Route
            path='company/:id'
            element={
              <SuspensedView>
                <CompanyDetails />
              </SuspensedView>
            }
          />
        )}
        {user?.role === 'admin' && (
          <Route
            path='add-company'
            element={
              <SuspensedView>
                <AddCompany />
              </SuspensedView>
            }
          />
        )}
        {/* {user?.role === 'admin' && ( */}
          <Route
            path='edit-company/:id'
            element={
              <SuspensedView>
                <EditCompany />
              </SuspensedView>
            }
          />
        {/* )} */}




        {user?.role === 'admin' && (
          <Route
          path='settings/services'
          element={
            <SuspensedView>
            <Services />
            </SuspensedView>
          }
          />
        )}
        {user?.role === 'admin' && (
          <Route
            path='settings/add-service'
            element={
              <SuspensedView>
                <AddService />
              </SuspensedView>
            }
          />
        )}

        {user?.role === 'admin' && (
          <Route
          path='settings/categories'
          element={
            <SuspensedView>
            <Categories />
            </SuspensedView>
          }
          />
        )}
        {user?.role === 'admin' && (
          <Route
            path='settings/add-category'
            element={
              <SuspensedView>
                <AddCategory />
              </SuspensedView>
            }
          />
        )}

        {user?.role === 'admin' && (
          <Route
          path='settings/items'
          element={
            <SuspensedView>
            <Items />
            </SuspensedView>
          }
          />
        )}
        {user?.role === 'admin' && (
          <Route
            path='settings/add-item'
            element={
              <SuspensedView>
                <AddItem />
              </SuspensedView>
            }
          />
        )}





        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
