import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {api} from '../../../helpers/api_helper'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setError(null)
      let response: any;
      try {
        response = await api('post', '/users/forgot', {
          email: values.email
        })
      }
      catch (error: any) {
        console.error(error);
        response = error?.response
      }

      if (response?.status == 200) {
        setSuccess('Please check your email for a link to reset your password.')
      }
      else {
        setError(response?.data?.msg || 'Unknown Error: Something went wrong')
      }

      setLoading(false)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-left mb-10'>
          <Link to='/auth/login'>
              <div className='d-flex align-items-center fs-5 fw-bolder'>
                <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2 ms-0 text-primary' />
                <span>Back to Login</span>
              </div>
          </Link>
        </div>

        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          <p className='text-gray-400 fw-bold'>Enter your email to reset your password.</p>
        </div>

        {error ? (
          <div className='mb-8 text-center'>
            <span className="badge badge-light-danger fs-5">{error}</span>
          </div>
        ) : <></>}

        {success ? (
          <div className='mb-8 text-center'>
            <span className="badge badge-light-success fs-5">{success}</span>
          </div>
        ) : (
          <>
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
              <input
                type='email'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
              >
                <span className='indicator-label'>Submit</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </>
        )}

      </form>
    </>
  )
}
