import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useNavigate, useParams, Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {api} from '../../helpers/api_helper'
import {useIntl} from 'react-intl'


export function PaymentMethod() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [paymentDetails, setPaymentDetails] = useState<any>({})
  const { id, token, type } = useParams()
  const navigate = useNavigate()

  const intl = useIntl()

  const getPaymentDetails = async () => {
    setLoading(true)
    let response: any
    try {
      response = await api('GET', `/payment_details/${id}/${token}`)
    }
    catch (error: any) {
      setError(error?.response?.data?.msg)
    }
    if (response?.status == 200) {
      setPaymentDetails(response.data?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    getPaymentDetails()
  }, [])

  const confirmQuotation = async () => {

  }

  const selectPayment = async (mode) => {
    setLoading(true)
    let response: any;
    try {
      response = await api('POST', `/payment_details/${id}/${token}`, {
        payment_mode: mode
      })
    }
    catch (error: any) {
      response = error?.response
      console.error(error);
      setError(response?.data?.msg || null)
    }

    if (response?.status == 200) {
      if (mode == 'cash') {
        window.location.href = '/payment-success/cash'
      }
      else {
        if (paymentDetails?.has_payment_details) {
          window.location.href = '/payment-success'
        }
        else {
          const link = response?.data?.data?.url
          if (link) {
            window.location.href = link
          }
        }
      }
    }

    setLoading(false)
  }

  const cardDetails = paymentDetails?.has_payment_details ?
    `${paymentDetails.details?.[0]?.card_brand}: xxxx-xxxx-xxxx-${paymentDetails.details?.[0]?.card_number}`
    : null

  const isSubscription = type == 'subscription'


  return (
    <>
      <div className='card w-100 h-100 mw-600px mx-auto my-10'>
        <div className='card-body d-flex flex-column align-items-center'>

          <div className='d-flex flex-column w-100px'>
            <button
              className='btn btn-sm btn-light'
              onClick={() => navigate(-1)}
            >
              {intl.formatMessage({id: 'COMMON.BACK'})}
            </button>
          </div>

          <div className='text-center mt-10'>
            <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'INVOICE.PAYMENT_METHOD'})}</h1>
            <p className='text-gray-400 fw-bold'>{intl.formatMessage({id: 'INVOICE.PAYMENT_METHOD.DESCRIPTION'})}</p>
            <p className='text-gray-400 fw-bold'>{intl.formatMessage({id: 'INVOICE.PAYMENT_METHOD.NOTE'})}</p>
            {error ? (
              <p className='badge badge-light-danger fw-bold fs-6 mt-5'>{error}</p>
            ) : <></>}
          </div>

          <div className='d-flex flex-column h-100 py-10 justify-content-center align-items-center'>
            <button
              className='btn btn-lg btn-light-primary'
              onClick={() => selectPayment('card')}
            >
              {loading ? (
                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
              ) : (
                <KTSVG
                  path='/media/icons/duotune/finance/fin002.svg'
                  className='svg-icon-2 menu-item-svg'
                />
              )}
              {cardDetails ? <span className='text-uppercase'>{cardDetails}</span> : intl.formatMessage({id: 'INVOICE.REGISTER_CARD'})}
            </button>

            {!isSubscription && (
              <button
                className='btn btn-lg btn-light-success mt-20'
                onClick={() => selectPayment('cash')}
              >
                {loading ? (
                  <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                ) : (
                  <KTSVG
                    path='/media/icons/duotune/finance/fin003.svg'
                    className='svg-icon-2 menu-item-svg'
                  />
                )}
                {intl.formatMessage({id: 'INVOICE.CASH'})}
              </button>
            )}
          </div>

        </div>
      </div>
    </>
  )
}
