import React  from "react";
import { useIntl } from 'react-intl'



export default function PrivacyData({ type }) {

  const intl = useIntl()

  const t = (id) => {
    return intl.formatMessage({ id })
  }


  return (
    <div className="privacy-content">
      <h2 className="legal-title">{t('GUIDELINE.PRIVACY_POLICY')}</h2>

      <span className="legal-text-formatted">{`
  株式会社ビートルマネージメント（以下「当社」といいます。）は、当社が「DUSTALK」（ダストーク）（以下「本サービス」といいます。）を提供するにあたり、ご利用されるお客様（以下「利用者」といいます。）の個人に関する情報（以下「個人情報」といいます。）を次の条項に従って取得します。

  第１条（適用範囲）
  本プライバシーポリシー（以下「本ポリシー」といいます。）は、当社が利用者から個人情報を取得、利用及び管理するときに適用されます。

  第２条（取得する情報）
  当社は、利用者から以下の情報を取得します。
  (1)氏名又は会社名
  (2)住所
  (3)連絡先
  (4)クレジットカード情報、銀行口座情報等の決済に関する情報
  (5)情報通信端末の機体識別に関する情報
  (6)位置情報
  (7)IP アドレス
  (8)閲覧したURL 及び日時に関するタイムスタンプ
  (9)利用者が廃棄物回収業者の場合、代表者名等、事業許可に関する情報

  第３条（利用目的）
  当社が本サービスにおいて個人情報を収集・利用する目的は、以下のとおりです。
  (1)提供・運営のため
  (2)運営上必要な事項の通知のため
  (3)利用者の管理のため
  (4)新機能、更新情報をお知らせするため
  (5)メールマガジンの送信、ダイレクトメールの送付のため
  (6)広告効果の分析、市場分析、マーケティングのため
  (7)キャンペーン、懸賞企画、アンケートの実施のため
  (8)各種問合せ、アフターサービス対応のため
  (9)不正アクセス、不正利用の防止のため
  (10)本サービス及びその他のコンテンツの開発・改善のため
  (11)当社が実施するサービス又は企画に関する連絡のため
  (12)上記の利用目的に付随する目的

  第４条（Cookie 等の利用）
  当社がCookie を利用する目的は、以下のとおりです。
  (1)ウェブサイト及び本サービス（以下、これらを総称して「ウェブサイト等」といいます。）のアクセス及び利用状況の分析、広告配信並びにウェブサイト等の利便性向上のために、ウェブサイト等にアクセスした利用者の情報通信端末に保存されるCookie 等のファイル及びこれに類似する技術を取得することがあります。
  (2)Cookie 等と利用者の個人情報を関連付けることがあります。
  (3)利用者の個人情報と関連付けたCookie 等が利用者の個人情報であることに鑑み、当該Cookie 等を本ポリシーの定めに従って管理いたします。
  (4)Cookie 等の情報を第三者が運営するデータ・マネジメント・プラットフォームに提供し、当該第三者からCookie 等により収集されたウェブの閲覧履歴及びその分析結果を取得し、これらを利用者の個人データと結び付けた上で、広告配信等の目的で利用することがあります。

  第５条（安全確保の措置）
  当社が情報の安全を確保する措置は、以下の通りです。
  (1)個人情報に関する法令及びガイドラインを遵守します。
  (2)事故が発生した場合には、再発防止策を含む適切な対策を、速やかに講じます。
  (3)適切な安全対策を実施し、個人情報への不正なアクセス、紛失、改ざん、漏洩等の危険防止に取り組み、事故の予防に努めます。

  第６条（個人情報の第三者への提供）
  １　当社は、以下の場合を除いて、あらかじめ利用者の同意を得ずに、取得した個人情報を第三者に提供することはありません。
  (1)法令に基づく場合
  (2)国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
  (3)その他法令で第三者提供の制限の例外が認められている場合
  ２　前項の定めにかかわらず、次に掲げる場合には、当該個人情報の提供先は第三者に該当しないものとします。
  (1)当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託することに伴って当該個人情報が提供される場合
  (2)合併その他の事由による事業の承継に伴って個人情報が提供される場合
  (3)第７条に定める共同利用者に対して提供される場合

  第７条（個人情報の共同利用）
  １　当社は、以下のとおり個人情報を共同利用します。
  (1)共同して利用される個人情報の項目は、本プライバシーポリシー第２条のとおりとする。
  (2)共同して利用する者の範囲
  名 称：株式会社Interakt
  (3)利用する者の利用目的は、本プライバシーポリシー第３条のとおりとする。
  ２　当該個人データの管理について責任を有する者は、以下の通りです。
  名　称：株式会社ビートルマネージメント
  住　所：福岡県北九州市八幡西区陣原二丁目８番２号
  代表者：西原　靖博

  第８条（本プライバシーポリシーの変更）
  当社は、法令改正への対応の必要性及び事業上の必要性に応じて、本プライバシーポリシーを変更する場合があります。本プライバシーポリシーの変更を行った場合には、本ウェブサイト上に掲載します。

  第９条（開示、訂正等の手続）
  １　利用者は、本条及び当社の関連規程に従って、当社に対し、個人情報保護法において認められる限度で、以下の求め又は請求を行うことができます。
  (1)個人情報の利用目的の通知の求め
  (2)個人情報又は第三者提供記録の開示の請求
  (3)個人情報の訂正、追加又は削除の請求
  (4)個人情報の利用の停止の請求
  (5)個人情報の第三者への提供の停止の請求
  ２　前項の求め又は請求にあたっては、同項各号のうちいずれの請求か特定の上、本人確認のための書類（運転免許証、健康保険証、住民票の写し等）をご提出頂きます。
  
  第１０条（お問い合わせ）
  当社の個人情報の取扱いに関するご相談等のお問い合わせについては、下記の窓口にご連絡ください。
  個人情報取扱事業者：株式会社ビートルマネージメント
  代表者名：西原　靖博
  住所：福岡県北九州市八幡西区陣原二丁目８番２号
  E メールアドレス：info@dusttalk.com
      `}</span>

      {/*

      {type == 'modal' ? (
        <p>株式会社ビートルマネージメント（以下「当社」）は、お預かりした個人情報について、以下のとおり適正かつ安全に<br/>管理、運用することを誓約します。</p>
      ) : (
        <p>株式会社ビートルマネージメント（以下<br/>「当社」）は、お預かりした個人情報に<br/>ついて、以下のとおり適正かつ安全に管理、<br/>運用することを誓約します。</p>
      )}

      <ol>
        <li>
          <p>利用目的</p>

          <p>当社は、収集した個人情報を、以下の目的のために利用いたします。</p>

          <ul>
            <li>廃棄物の収集のため</li>
            <li>お問い合わせへの回答のため</li>
          </ul>
        </li>

        <li>
          <p>第三者提供</p>

          <p>当社は以下の場合を除き、個人情報を第三者へ提供しません。</p>
          <ul>
            <li>法令に基づく場合</li>
            <li>国の機関や地方公共団体、その委託者などによる法令事務の遂行にあたって協力する必要があり、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
            <li>人の生命・身体・財産を保護するために必要かつ、本人から同意を得ることが難しい場合</li>
          </ul>
        </li>

        <li>
          <p>開示請求</p>

          <p>お預かりした個人情報について、ご本人には開示・訂正・削除・利用停止を請求する権利があります。手続きはご本人確認のうえ対応させていただきます（代理人可能）。詳細は、以下「問い合わせ先」へご連絡ください。</p>
        </li>

        <li>
          <p>問い合わせ先</p>

          <p>福岡県北九州市八幡西区陣原２丁目8―２</p>
          <p>株式会社ビートルマネージメント</p>
          <p>個人情報問い合わせ窓口</p>
          <p>TEL: 093-644-0158 </p>
          <p>FAX: 093-644-0168</p>
          <p>E-mail: info@beetlemanagement.com</p>
        </li>
      </ol>

      */}
    </div>
  )
}
