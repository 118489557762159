/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FC,
  VFC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  cloneElement
} from 'react'
import {useNavigate} from 'react-router-dom'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {api, getToken, clearToken} from '../../../helpers/api_helper'

// type AuthContextProps = {
//   auth: AuthModel | undefined
//   saveAuth: (auth: AuthModel | undefined) => void
//   currentUser: UserModel | undefined
//   setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
//   logout: () => void
// }
//
// const initAuthContextPropsState = {
//   auth: authHelper.getAuth(),
//   saveAuth: () => {},
//   currentUser: undefined,
//   setCurrentUser: () => {},
//   logout: () => {},
// }

interface UserModel {
  token: string;
  name?: string;
  email?: string;
  role?: string;
  companyDetails?: any;
}

type AuthContext = {
  user: UserModel | undefined
  setUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const authContext = createContext<AuthContext>({
  user: undefined,
  setUser: () => {},
  logout: () => {}
})

const useAuth = () => {
  return useContext(authContext)
}

interface AuthProps {
  setIsUserFetched?: any;
  redirectLogin?: any;
}


const AuthProvider = (props: any) => {
  const [user, setUser] = useState<UserModel | undefined>(undefined)

  const { isUserFetched, setIsUserFetched, redirectLogin } = props

  const fetchUser = async (token: string) => {
    let response: any;
    try {
      response = await api('GET', '/users/me')
    }
    catch (error) {
    }

    if (response?.status == 200) {
      setUser(response.data.data)
    }
    setIsUserFetched(true)
  }

  const setNoUser = () => {
    setUser(undefined)
    setIsUserFetched(true)
  }

  const logout = () => {
    setUser(undefined)
    clearToken()
  }

  useEffect(() => {
    const token = getToken()
    if (token) {
      fetchUser(token)
    }
    else {
      setNoUser()
    }
  }, [])

  // useEffect(() => {
  // }, [user])




  return (
    <authContext.Provider value={{user, setUser, logout}}>
      {cloneElement(props.children, { user, setUser, logout })}
    </authContext.Provider>
  )
}




const AuthInit = ({children}) => {
  return <></>
}




// const AuthInit: FC = ({children}) => {
//   const {auth, logout, setCurrentUser} = useAuth()
//   const didRequest = useRef(false)
//   const [showSplashScreen, setShowSplashScreen] = useState(true)
//   // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
//   useEffect(() => {
//     const requestUser = async (apiToken: string) => {
//       try {
//         if (!didRequest.current) {
//           const {data} = await getUserByToken(apiToken)
//           if (data) {
//             setCurrentUser(data)
//           }
//         }
//       } catch (error) {
//         console.error(error)
//         if (!didRequest.current) {
//           logout()
//         }
//       } finally {
//         setShowSplashScreen(false)
//       }
//
//       return () => (didRequest.current = true)
//     }
//
//     if (auth && auth.api_token) {
//       requestUser(auth.api_token)
//     } else {
//       logout()
//       setShowSplashScreen(false)
//     }
//     // eslint-disable-next-line
//   }, [])
//
//   return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
// }

export {AuthProvider, AuthInit, useAuth}
export type {AuthProps}
