/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState, useEffect , ChangeEvent} from 'react'
import {useField,ErrorMessage,useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl , useDebounce} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {api} from '../../../helpers/api_helper'
import {useIntl} from 'react-intl'
import Select  from 'react-select';
import Downshift from 'downshift';
import axios from 'axios'



export function Registration() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [selectedHolidays, setSelectedHolidays] = useState<any>([])
  const [logo, setLogo] = useState<any | undefined>(undefined)
  const [logoPreview, setLogoPreview] = useState<string | undefined>(undefined)
  const [permit, setPermit] = useState<File[]>([]); 
  const [permitPreview, setPermitPreview] = useState<string[]>([]); 
  const [selectedBankCode , setSelectedBankCode] = useState<string | undefined>();
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedBankBranch, setSelectedBankBranch] = useState<{name : string , code : string}>();
  const [bankOptions, setBankOptions] = useState<{ name: string , code : string}[]>([]);
  const [bankBranchOptions, setBankBranchOptions] = useState<{ name: string , code : string}[]>([]);
  const [isLoadingBankBranches, setIsLoadingBankBranches] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [areaWiseLisence, setAreaWiseLisence] = useState<any>([])
  const [licenseNo, setLicenseNo] = useState<string>('');
  const [prefectures, setPrefectures] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [selectedPrefecture, setSelectedPrefecture] = useState<any>(undefined)
  const [selectedCity, setSelectedCity] = useState<any>(undefined)
  const [companyCities, setCompanyCities] = useState<any>({})
  const [cityError, setCityError] = useState<string>('')
  const [licenseError, setLisenceError] = useState<string>('')




  const {setUser} = useAuth()

  const intl = useIntl()
  const locale = intl?.locale
  

  const bankAccTypes = [
    {option : intl.formatMessage({id: 'ORDINARY'}) , value : '普通預金'},
    {option : intl.formatMessage({id: 'CURRENT'}) , value : '当座預金'},
    {option : intl.formatMessage({id: 'SAVING'}) , value : '貯蓄預金'}
  ]

  const holidayOptions = [
    { label: intl.formatMessage({ id: 'MON' }), value: '月曜日' },
    { label: intl.formatMessage({ id: 'TUE' }), value: '火曜日' },
    { label: intl.formatMessage({ id: 'WED' }), value: '水曜日' },
    { label: intl.formatMessage({ id: 'THR' }), value: '木曜日' },
    { label: intl.formatMessage({ id: 'FRI' }), value: '金曜日' },
    { label: intl.formatMessage({ id: 'SAT' }), value: '土曜日' },
    { label: intl.formatMessage({ id: 'SUN' }), value: '日曜日' }
  ];
  
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


  const registrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'COMPANY.NAME_ERROR'})),
    company_url: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'})),
      // .required(intl.formatMessage({id: 'COMPANY.URL_ERROR'})),
    username : Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'NAME_ERROR'})),
    email: Yup.string()
      .email('Invalid email format')
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'COMPANY.EMAIL_ERROR'})),
    phoneNumber: Yup.string()
      .required(intl.formatMessage({id: 'PH_REQUIRED'})),
      // .matches(phoneRegExp, intl.formatMessage({id: 'NUM_NOT_VALID'}))
      // .min(10, intl.formatMessage({id: 'MIN_10_SYM'}))
      // .max(10, intl.formatMessage({id: 'MIN_10_SYM'})),
    // holiday: Yup.array(),
    // .min(1, intl.formatMessage({ id: 'COMPANY.HOLIDAY_SELECTION_ERROR' })),
    location: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'LOCATION_ERROR'})),
    postalCode: Yup.string()
      .matches(/\d{3}-\d{4}/, intl.formatMessage({id: 'POSTAL_FORMAT'}))
      .length(8, intl.formatMessage({id: 'POSTAL_FORMAT'}))
      .required(intl.formatMessage({id: 'POSTAL_CODE_ERROR'})),
    bankName: Yup.string()
      .min(1, intl.formatMessage({id: 'MIN_1_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .test('bankSelected', intl.formatMessage({ id: 'INVALID_BANKNAME' }), (value) => {
        if ((value && bankOptions.length === 0) || value !== bankOptions[0]?.name) {
          return false;
        }
        return true;
      })
      .required(intl.formatMessage({id: 'BANK_NAME_ERROR'})),
    bankBranch: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .test('bankBranchSelected', intl.formatMessage({ id: 'INVALID_BANKBRANCH' }), (value) => {
        if (!value || value.trim() === '') {
          return false; // No bank branch selected
        }
        if (bankBranchOptions.length === 0) {
          return false; // Options not available
        }
        return true;
      })
      .required(intl.formatMessage({id: 'BANK_BRANCH_ERROR'})),
    bankType: Yup.string()
      .required(intl.formatMessage({id: 'BANK_TYPE_ERROR'})),
    bankAccountNumber: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'BANK_ACCOUNTNO_ERROR'})),
    // license: Yup.string()
    //   .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
    //   .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
    //   .required(intl.formatMessage({id: 'LISENSENO_ERROR'})),
    register_number: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'REGISTERNO_ERROR'})),
    // password: Yup.string()
    //   .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
    //   .max(50, 'Maximum 50 symbols')
    //   .required('Password is required'),
    // confirmPassword: Yup.string()
    //   .required('Password confirmation is required')
    //   .when('password', {
    //     is: (val: string) => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    //   }),
    acceptTerms: Yup.bool().required(intl.formatMessage({id: 'ACCEPT_TERMS_CONDITIONS'})),
  })

  const initialValues = {
    name: '',
    // description: '',
    company_url: '',
    username : '',
    email: '',
    location: '',
    postalCode: '',
    // holiday:[] as string[],
    bankName: '',
    bankBranch: '',
    phoneNumber : '',
    bankType: '',
    bankAccountNumber: '',
    // license: '',
    // available_from: '',
    // available_to: '',
    register_number : '',
    acceptTerms: false,
    acceptPrivacy:false,
  }


  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setCityError('')
      setLisenceError('')

      // const { name , holiday } = values
      const { name  } = values

       
      const lettersOrJapanese = /[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z]+|[ａ-ｚＡ-Ｚ]+|[々〆〤ヶ]+/u
      const hasLettersOrJapanese = !!name.match(lettersOrJapanese)
      if (!hasLettersOrJapanese) {
        setError(intl.formatMessage({id: 'CHARACTERS_ERROR'}))
        return;
      }

      if (!Object.keys(companyCities)?.length) {
        setCityError(intl.formatMessage({id: 'ATLEAST_1_CITY_ERROR'}))
        return;
      }

      let params = new FormData()
      params.append('name', values.name)
      params.append('username', values.username)
      // params.append('description', values.description)
      params.append('website_url', values.company_url)
      params.append('company_email', values.email)
      params.append('location', values.location)
      params.append('postal_code', values.postalCode)
      params.append('bank_name', values.bankName)
      // params.append('holiday', holiday.toString());
      params.append('branch', values.bankBranch)
      params.append('phone_number', values.phoneNumber)
      params.append('deposit_type', values.bankType)
      params.append('bank_number', values.bankAccountNumber)
      // params.append('license_no', values.license)
      
      for (let cityLicense of areaWiseLisence) {
        params.append('area_licenses', JSON.stringify({
          city_id: cityLicense.city_id,
          license: cityLicense.license
        }));
      }
      params.append('registration_number', values.register_number)
      // params.append('available_times', JSON.stringify({
      //   from: values.available_from,
      //   to: values.available_to
      // }))
      // params.append('logo', logo)
      // params.append('license_image', permit)
      permit.forEach((file) => {
        params.append('license_image', file);
      });
      Object.keys(companyCities).forEach(cityID => {
        params.append('cities', cityID)
      })

      setLoading(true)
      let response: any;
      try {
        response = await api('post', '/company/signup', params)
      }
      catch (error: any) {
        response = error?.response
      }
      setLoading(false)

      if (response?.status == 200) {
        setSuccess('Application submitted successfully!')
      }
      else if (response?.status == 404) {
        setError('404 Not Found')
      }
      else {
        setError(response?.data?.msg)
      }
    },
  })


  function onChangeHolidays(values) {
    const selectedValues = values.map((option) => option.value);
    setSelectedHolidays(selectedValues);
    formik.setFieldValue("holiday", selectedValues);
  }

  useEffect(() => {
    getPrefectures()
    PasswordMeterComponent.bootstrap()
  }, [])


  const getBankName = async (bankName :any) => {
    if (bankName !== '') {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://apis.bankcode-jp.com/v2/banks/?filter=name==${bankName}&apikey=${process.env.REACT_APP_BANKCODESJP_KEYS}&limit=10`
        );
        if (response.data.size === 0) {
          formik.setFieldError('bankName', intl.formatMessage({ id: 'INVALID_BANKNAME' }));
          setBankOptions([{ name: intl.formatMessage({ id: 'NO_BANKNAME' }), code: '' }]);
        } else {
          const bankList = response?.data?.data;
          formik.setFieldError('bankName', '');
          setBankOptions(bankList.map((bank :any) => ({ name: bank.name, code: bank.code })));
        }
      } catch (err) {
      }
      setIsLoading(false);
    }
  };

  const debouncedBankName = useDebounce(formik.values.bankName, 2000);

  useEffect(() => {
    getBankName(debouncedBankName);
  }, [debouncedBankName]);

  useEffect(() => {
    setTimeout(()=>{
      getBankBranch();
    },2000)
  }, [selectedBankCode]);

  const getBankBranch = async () => {
    if (selectedBankCode) {
      setIsLoadingBankBranches(true);
      try {
        const response = await axios.get(
          `https://apis.bankcode-jp.com/v2/banks/${selectedBankCode}/branches?apikey=${process.env.REACT_APP_BANKCODESJP_KEYS}&limit=1000`
        );
        if (response.data.size === 0) {
          formik.setFieldError('bankBranch', intl.formatMessage({ id: 'INVALID_BANKBRANCH' }));
          setBankBranchOptions([{ name: intl.formatMessage({ id: 'NO_BANKBRANCH' }), code: '' }]);
        } else {
          const bankBranchList = response?.data?.data;

          formik.setFieldError('bankBranch', '');
          // setBankBranchOptions(bankBranchList.map((bankBranch: any) => ({ name: bankBranch.name, code: bankBranch.code })));
          setBankBranchOptions(
            bankBranchList.map((bankBranch: {name : string , code : string}) => ({
              value: bankBranch.code,
              label: bankBranch.name
            }))
          );
        }
      } catch (err) {
      }
      setIsLoadingBankBranches(false);
    }
  };


  const getPrefectures = async () => {
    let response
    try {
      response = await api('post', '/service/request/prefectures', {})
      setPrefectures(response?.data?.data)
    }
    catch (error: any) {
      response = error?.response
      // setPrefectures(['Test Prefecture'])
    }
  }

  useEffect(() => {
    onSelectPrefecture()
    setSelectedCity(undefined)
    if (!selectedPrefecture) {
      setCities([])
    }
  }, [selectedPrefecture])

  const onSelectPrefecture = async () => {
    let response
    try {
      response = await api('post', '/service/request/cities', {
        prefecture: selectedPrefecture
      })
      setCities(response?.data?.data)
    }
    catch (error: any) {
      response = error?.response
      // setCities([{ _id: '123', city: { en: 'Test City', jp: 'Test City' } }])
    }
  }

  const addCity = (e) => {
    e.preventDefault()
    setCityError('')
    setLisenceError('')

    if (!selectedCity) {
      setCityError(intl.formatMessage({id:'SELECT_CITY'}))
      return;
    }

    if (!licenseNo) {
      setLisenceError(intl.formatMessage({ id: 'LICENSE_REQ' }));
      return;
    }

    let newCities = { ...companyCities }
    newCities[selectedCity?._id] = selectedCity
    setCompanyCities(newCities)

    const newLicenseEntry = {
      city_id: selectedCity?._id,
      license: licenseNo,
    };
    setAreaWiseLisence((prevLicenses) => [...prevLicenses, newLicenseEntry]);

    setLicenseNo('');
  }


  const openLogoFileSelect = () => {
    const fileSelect = document.getElementById('logo-file-select')
    if (fileSelect) {
      fileSelect.click()
    }
  }

  const openPermitFileSelect = () => {
    const fileSelect = document.getElementById('permit-file-select')
    if (fileSelect) {
      fileSelect.click()
    }
  }

  const handleLogoFileSelect = (e: any) => {
    const file = e.target.files[0]

    if (file) {
      let fileReader = new FileReader()
      fileReader.onload = function(e) {
        let result = e?.target?.result ? e.target.result.toString() : undefined
        setLogoPreview(result)
      }
      fileReader.readAsDataURL(file)
      setLogo(file)
    }
  }

  const handlePermitFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
  
    if (files) {
      const newFilesArray: File[] = Array.from(files); 
      const newPermitArray: File[] = [...permit, ...newFilesArray]; 
  
      let fileReader = new FileReader();
      fileReader.onload = function (e) {
        let result = e?.target?.result ? e.target.result.toString() : undefined;
       
        setPermitPreview((prevPreview: string[] | undefined) => {
          if (prevPreview !== undefined) {
            return [...prevPreview, result] as string[];
          } else {
            return [result] as string[];
          }
        });
        
      };
  
      newFilesArray.forEach((file) => fileReader.readAsDataURL(file));
      setPermit(newPermitArray);
    }
  };
  

  // const handlePermitFileSelect = (e: any) => {
  //   const file = e.target.files[0]

  //   if (file) {
  //     let fileReader = new FileReader()
  //     fileReader.onload = function(e) {
  //       let result = e?.target?.result ? e.target.result.toString() : undefined
  //       setPermitPreview(result)
  //     }
  //     fileReader.readAsDataURL(file)
  //     setPermit(file)
  //   }
  // }

  const deleteLogoPreview = (e: any) => {
    if (e) e.stopPropagation()
    setLogo(undefined)
    setLogoPreview(undefined)
  }

  const deletePermitPreview = (index: number) => {
    const newPermitArray = [...permit];
    const newPermitPreviewArray = [...permitPreview];
  
    newPermitArray.splice(index, 1); 
    newPermitPreviewArray.splice(index, 1); 
  
    setPermit(newPermitArray);
    setPermitPreview(newPermitPreviewArray);
  };

  // const deletePermitPreview = (e: any) => {
  //   if (e) e.stopPropagation()
  //   setPermit(undefined)
  //   setPermitPreview(undefined)
  // }

  useEffect(() => {
    if (selectedBank) {
      const selectedBankObj = bankOptions.find((bank) => bank.name === selectedBank);
      if (selectedBankObj) {
        setSelectedBankCode(selectedBankObj.code);
      }
    } else {
      setSelectedBankCode(undefined);
    }
  }, [selectedBank, bankOptions]);
  
  
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >

      <div className='text-gray-400 fw-bold fs-4 text-center mb-5'>
        <p className='fs-6 mb-2'>{intl.formatMessage({id: 'AUTH.HAVE_AN_ACCOUNT'})}</p>
        <Link to='/auth/login' className='link-primary fw-bolder'>
          {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}
        </Link>
      </div>

      <div className='d-flex align-items-center mb-5'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 nowrap fs-7 mx-2'>{intl.formatMessage({id: 'OR'})}</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-4'>{intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}</h1>
        <p className='text-muted mb-0'>{intl.formatMessage({id: 'AUTH.REGISTER.SUBTITLE_1'})}</p>
        <p className='text-muted mb-0 '>{intl.formatMessage({id: 'AUTH.REGISTER.SUBTITLE_2'})}</p>
        <p className='text-muted mb-0'>{intl.formatMessage({id: 'AUTH.REGISTER.SUBTITLE_3'})}</p>
      </div>

      {!success ? (
        <>
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}


          {/* <div className='d-flex flex-column align-items-center mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 mb-4'>{intl.formatMessage({id: 'AUTH.REGISTER.LOGO'})}</label>
            <input
              type='file'
              multiple={false}
              className='d-none'
              id='logo-file-select'
              required
              onChange={handleLogoFileSelect}
            />
            <div
              className='d-flex justify-content-center align-items-center border border-primary border-hover-success text-hover-success rounded p-5'
              onClick={openLogoFileSelect}
              role='button'
              style={{ position: 'relative', width: '80px', height: '80px' }}
            >
              {logoPreview ? (
                <>
                  <img src={logoPreview} width="100%" height="100%" style={{ objectFit: 'cover' }} />
                  <button
                    className='btn btn-sm btn-danger'
                    style={{ position: 'absolute', top: '-1rem', right: '-1rem' }}
                    onClick={deleteLogoPreview}
                  >
                    ✕
                  </button>
                </>
              ): (
                <KTSVG
                  path='/media/icons/duotune/arrows/arr075.svg'
                  className='svg-icon-2x text-primary d-block my-2'
                />
              )}
            </div>
          </div> */}

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'COMPANY.NAME'})}*</label>
            <input
              placeholder={intl.formatMessage({id: 'COMPANY.NAME'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.name && formik.errors.name,
                },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
          </div>

          {/*
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.DESCRIPTION'})}</label>
            <input
              placeholder='Description of Company'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('description')}
              className='form-control form-control-lg form-control-solid'
            />
          </div>
          */}

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.URL'})}</label>
            <input
              placeholder={intl.formatMessage({id: 'COMPANY.URL'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('company_url')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.company_url && formik.errors.company_url,
                },
                {
                  'is-valid': formik.touched.company_url && !formik.errors.company_url,
                }
              )}
            />
          </div>

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.EMAIL'})}*</label>
            <input
              placeholder={intl.formatMessage({id: 'COMPANY.EMAIL'})}
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.NAME'})}*</label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.REGISTER.NAME'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.username && formik.errors.username,
                },
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'COMMON.POSTAL_CODE'})}*</label>
            <input
              placeholder={intl.formatMessage({id: 'COMMON.POSTAL_CODE'})}
              type='tel'
              autoComplete='off'
              {...formik.getFieldProps('postalCode')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.postalCode && formik.errors.postalCode,
                },
                {
                  'is-valid': formik.touched.postalCode && !formik.errors.postalCode,
                }
              )}
            />
            {formik.touched.postalCode && formik.errors.postalCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.postalCode}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'COMMON.LOCATION'})}*</label>
            <input
              placeholder={intl.formatMessage({id: 'COMMON.LOCATION'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('location')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.location && formik.errors.location,
                },
                {
                  'is-valid': formik.touched.location && !formik.errors.location,
                }
              )}
            />
            {formik.touched.location && formik.errors.location && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.location}</span>
                </div>
              </div>
            )}
          </div>

          {/* <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'COMPANIES.AVAILABLE_TIMES'})}*</label>
          <div className='fv-row d-flex justify-content-center align-items-center mb-4'>
            <div className='w-100'>
              <p>{intl.formatMessage({id: 'COMMON.FROM_TIME'})}</p>
              <input
                placeholder='License No.'
                type='time'
                autoComplete='off'
                {...formik.getFieldProps('available_from')}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
            <div className='w-100 ms-5'>
              <p>{intl.formatMessage({id: 'COMMON.TO_TIME'})}</p>
              <input
                placeholder='License No.'
                type='time'
                autoComplete='off'
                {...formik.getFieldProps('available_to')}
                className='form-control form-control-lg form-control-solid'
              />
            </div>
          </div>
          {formik.touched.available_from && formik.errors.available_from && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>{formik.errors.available_from}</span>
              </div>
            </div>
          )}
          {formik.touched.available_to && formik.errors.available_to && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger'>{formik.errors.available_to}</span>
              </div>
            </div>
          )}
           */}
            {/* <div className="fv-row mb-5">
                      <label className="form-label fw-bolder text-dark fs-6">{intl.formatMessage({ id: 'SELECT_HOLIDAY' })}</label>
                        <Select
                            {...formik.getFieldProps('holiday')}
                            isMulti
                            options={holidayOptions}
                            className="border-light-blue text-light-blue"
                            name="selectedUsers"
                            value={selectedHolidays?.value}
                            onChange={(values) => onChangeHolidays(values)}
                          />
                     
                      {formik.touched.holiday && formik.errors.holiday && (
                        <div className='fv-plugins-message-container'>
                          <span className="text-danger">{formik.errors.holiday}</span>
                        </div>
                      )}
                </div> */}
               <div className='fv-row mb-5'>
                   <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'COMMON.PH_NUM'})}*</label>
                   <input
                     placeholder={intl.formatMessage({id: 'COMMON.PH_NUM'})}
                     type='text'
                     autoComplete='off'
                     {...formik.getFieldProps('phoneNumber')}
                     className={clsx(
                       'form-control form-control-lg form-control-solid',
                       {
                         'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber,
                       },
                       {
                         'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                       }
                     )}
                   />
                   {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                     <div className='fv-plugins-message-container'>
                       <div className='fv-help-block'>
                         <span role='alert'>{formik.errors.phoneNumber}</span>
                       </div>
                     </div>
                   )}
              </div>
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'COMPANIES.CITIES'})}</label>

            <div className='d-flex justify-content-between align-items-center mb-5'>
              <div className='w-100 me-5'>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  value={selectedPrefecture}
                  onChange={(e) => setSelectedPrefecture(e.target.value)}
                >
                  <option value=''>{intl.formatMessage({id: 'COMPANIES.SELECT_PREFECTURE'})}</option>
                  {prefectures.map(prefecture => {
                    return (
                      <option value={prefecture}>{prefecture}</option>
                    )
                  })}
                </select>
              </div>

              <div className='w-100'>
                <select
                  className={`form-select form-select-solid ${!selectedPrefecture ? 'select-disabled' : ''}`}
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  value={selectedCity?._id || ''}
                  onChange={(e) => setSelectedCity(cities.find(city => city?._id == e.target.value))}
                >
                  <option value=''>{intl.formatMessage({id: 'COMPANIES.SELECT_CITY'})}</option>
                  {cities.map(city => {
                    return (
                      <option value={city?._id}>{city?.city?.[locale]}</option>
                    )
                  })}
                </select>
              </div>
            </div>

               {!!cityError && (
                 <div className='fv-plugins-message-container'>
                   <div className='fv-help-block text-danger'>
                     <span role='alert'>{cityError}</span>
                   </div>
                 </div>
               )}
             </div>


               {/* {Object.values(companyCities).map((city: any) => {
                 return (
                   <p className="fw-bolder fs-5">{city?.prefecture?.[locale]} {city?.city?.[locale]}</p>
                 )
               })} */}

              <div className='fv-row mb-5'>
                  <div className='d-flex align-items-start'>
                    <label className='form-label fw-bolder text-dark fs-6' style={{ width: '150px', paddingTop: '8px' }}>
                      {intl.formatMessage({ id: 'CITY.LICENSE_NO' })}*
                    </label>
                    <div style={{ width: 'calc(100% - 150px)' }}>
                      <input
                        placeholder={intl.formatMessage({ id: 'CITY.LICENSE_NO' })}
                        type='text'
                        autoComplete='off'
                        value={licenseNo}
                        onChange={(e) => setLicenseNo(e.target.value)}
                        className='form-control form-control-lg form-control-solid'
                      />
                      {licenseError && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block text-danger'>
                              <span role='alert'>{licenseError}</span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                <button
                className='btn btn-primary nowrap ms-5 mb-3 '
                onClick={addCity}
              >{intl.formatMessage({id: 'ADD'})}</button>
                
             {Object.values(companyCities).map((city: any) => {
                const licenseEntry = areaWiseLisence.find((entry: any) => entry.city_id === city?._id);

                return (
                  <div key={city?._id} className="fw-bolder fs-5 mb-3">
                    {city?.prefecture?.[locale]} {city?.city?.[locale]}
                    {licenseEntry && ` - ${intl.formatMessage({id:'COMPANIES.LICENSE_NO'})}: ${licenseEntry.license}`}
                  </div>
                );
              })}

              
          {/* <div className='fv-row mb-5'>
            <div className='d-flex align-items-start'>
              <label className='form-label fw-bolder text-dark fs-6' style={{ width: '150px', paddingTop: '8px' }}>
                {intl.formatMessage({id: 'COMPANIES.LICENSE_NO'})}*
              </label>
              <div style={{ width: 'calc(100% - 150px)' }}>
                <input
                  placeholder={intl.formatMessage({id: 'COMPANIES.LICENSE_NO'})}
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('license')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.license && formik.errors.license,
                    },
                    {
                      'is-valid': formik.touched.license && !formik.errors.license,
                    }
                  )}
                />
                {formik.touched.license && formik.errors.license && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.license}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}


          <div className="d-flex flex-column align-items-center mb-5">
              <label className="form-label fw-bolder text-dark fs-6 mb-4" style={{ textAlign: 'left', alignSelf: 'flex-start' }}>
                {intl.formatMessage({ id: 'COMPANIES.LICENSE_IMAGE' })}*
              </label>
              <input
                type="file"
                multiple
                className="d-none"
                id="permit-file-select"
                onChange={handlePermitFileSelect}
              />
              <div
                className="d-flex flex-wrap justify-content-start align-items-center border border-primary border-hover-success text-hover-success rounded p-1"
                style={{ width: '100%' }}
              >
                {permitPreview.map((preview, index) => (
                  <div key={index} className="position-relative m-2">
                    <img
                      src={preview}
                      alt="Permit Preview"
                      className="img-fluid rounded"
                      style={{ width: '70px', height: '70px', objectFit: 'cover' }}
                    />
                    <button
                      className="btn btn-sm btn-danger position-absolute top-0 end-0"
                      onClick={() => deletePermitPreview(index)}
                      style={{ zIndex: '1' }}
                    >
                      &#10005;
                    </button>
                  </div>
                ))}
                <label
                  htmlFor="permit-file-select"
                  className="d-flex justify-content-center align-items-center m-2"
                  style={{ width: '70px', height: '70px', border: '1px dashed #ADB5BD', borderRadius: '4px', cursor: 'pointer' }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2x text-primary"
                  />
                </label>
              </div>
          </div>



          {/* <div className='d-flex flex-column align-items-center mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 mb-4'>{intl.formatMessage({id: 'COMPANIES.LICENSE_IMAGE'})}*</label>
            <input
              type='file'
              multiple={false}
              className='d-none'
              id='permit-file-select'
              onChange={handlePermitFileSelect}
            />
            <div
              className='d-flex justify-content-center align-items-center border border-primary border-hover-success text-hover-success rounded p-5'
              onClick={openPermitFileSelect}
              role='button'
              style={{ position: 'relative', width: '120px', height: '120px' }}
            >
              {permitPreview ? (
                <>
                  <img src={permitPreview} width="100%" height="100%" style={{ objectFit: 'cover' }} />
                  <button
                    className='btn btn-sm btn-danger'
                    style={{ position: 'absolute', top: '-1rem', right: '-1rem' }}
                    onClick={deletePermitPreview}
                  >
                    ✕
                  </button>
                </>
              ): (
                <KTSVG
                  path='/media/icons/duotune/arrows/arr075.svg'
                  className='svg-icon-2x text-primary d-block my-2'
                />
              )}
            </div>
          </div> */}

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.BANK_NAME'})}*</label>
              <input
                  placeholder={intl.formatMessage({ id: 'AUTH.REGISTER.BANK_NAME' })}
                  type='text'
                  // autoComplete='off'
                  {...formik.getFieldProps('bankName')}
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': formik.touched.bankName && formik.errors.bankName,
                  })}
                  value={formik.values.bankName}
                  onChange={(e) => {
                    const { value } = e.target;
                      setSelectedBank(value);
                    formik.handleChange(e);
                  }}
                  />
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'WAIT'})}{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                 )}
                {!isLoading && (
                    <div>
                      {bankOptions.length > 0 && (
                          <select
                                className='form-select mt-2'
                                value={selectedBank}
                                onChange={(e) => {
                                  const selectedBankName = e.target.value;
                                  setSelectedBank(selectedBankName);
                                }}
                              >
                                {bankOptions.map((bank) => (
                                  <option key={bank.code} value={bank.name}>
                                    {bank.name}
                                  </option>
                                ))}
                         </select>
                      )}
                    </div>
                  )}
            {/* {formik.touched.bankName && formik.errors.bankName && ( */}
            {/* {formik.touched.bankName && formik.errors.bankName && formik.values.bankName !== intl.formatMessage({ id: 'NO_BANKNAME' }) && ( */}
            {formik.touched.bankName && formik.errors.bankName && !selectedBank && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankName}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.BANK_BRANCH'})}*</label>
            {!isLoadingBankBranches && (
              <Select
                     options={bankBranchOptions}
                     value={selectedBankBranch}
                     onChange={(selectedOption : any) => {
                      formik.setFieldValue('bankBranch', selectedOption ? selectedOption.label : '');
                      }}
                     className={clsx(
                       'form-control-solid',
                       {
                         'is-invalid': formik.touched.bankBranch && formik.errors.bankBranch,
                       },
                       {
                         'is-valid': formik.touched.bankBranch && !formik.errors.bankBranch,
                       }
                     )}
                     placeholder={
                       bankBranchOptions.length === 0
                         ? intl.formatMessage({ id: 'FILL_BANKNAME' })
                         : intl.formatMessage({ id: 'SELECT.BANK_BRANCH' })
                     }
                  />
                )}
            {isLoadingBankBranches && <span>{intl.formatMessage({ id: 'LOADING_BANKBRANCHES' })}</span>}
            {formik.touched.bankBranch && formik.errors.bankBranch && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankBranch}</span>
                </div>
              </div>
            )}
          </div>

          

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.BANK_TYPE'})}*</label>
                    <select
                      {...formik.getFieldProps('bankType')}
                      className='form-select border-light-blue text-light-blue'
                      onChange={(e) => {
                        formik.setFieldValue("bankType", e.target.value);
                      }
                      }
                      name="bankType"
                      // value={bankType}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'Select' })}</option>
                      {
                        bankAccTypes.map((bankType: any, index) => {
                          return (
                            <option key={index.toString()} value={bankType.value}>{bankType.option}</option>
                          )
                        })
                      }
                    </select>
                    {formik.touched.bankType && formik.errors.bankType && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formik.errors.bankType}</span>
                      </div>
                    )}
            {/* <input
              placeholder={intl.formatMessage({id: 'AUTH.REGISTER.BANK_TYPE'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('bankType')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.bankType && formik.errors.bankType,
                },
                {
                  'is-valid': formik.touched.bankType && !formik.errors.bankType,
                }
              )}
            />
            {formik.touched.bankType && formik.errors.bankType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankType}</span>
                </div>
              </div>
            )} */}
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.BANK_ACCOUNT_NO'})}*</label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.REGISTER.BANK_ACCOUNT_NO'})}
              type='number'
              autoComplete='off'
              {...formik.getFieldProps('bankAccountNumber')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.bankAccountNumber && formik.errors.bankAccountNumber,
                },
                {
                  'is-valid': formik.touched.bankAccountNumber && !formik.errors.bankAccountNumber,
                }
              )}
            />
            {formik.touched.bankAccountNumber && formik.errors.bankAccountNumber && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bankAccountNumber}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'AUTH.REGISTER.REGISTRATION_NO'})}*</label>
            <input
              placeholder={intl.formatMessage({id: 'AUTH.REGISTER.REGISTRATION_NO'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('register_number')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.register_number && formik.errors.register_number,
                },
                {
                  'is-valid': formik.touched.register_number && !formik.errors.register_number,
                }
              )}
            />
            {formik.touched.register_number && formik.errors.register_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.register_number}</span>
                </div>
              </div>
            )}
          </div>


          <br/>


          {/*
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Password</label>
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
            </div>
            <div className='text-muted'>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </div>
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <input
              type='password'
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
          */}



          <div className='fv-row mt-5 mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                <Link to='/contract-agreement' className='ms-1 link-primary'>
                  {intl.formatMessage({id: 'AUTH.REGISTER.AGREE_TEXT'})}
                </Link>
                {intl.formatMessage({id: 'AUTH.REGISTER.AGREE_TEXT2'})}
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mt-5 mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptPrivacy')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                <Link to='/privacy-agreement' className='ms-1 link-primary'>
                  {intl.formatMessage({id: 'AUTH.REGISTER.AGREE_PRIVACY'})}
                </Link>
                {intl.formatMessage({id: 'AUTH.REGISTER.AGREE_PRIVACY2'})}
              </label>
              {formik.touched.acceptPrivacy && formik.errors.acceptPrivacy && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.acceptPrivacy}</span>
                  </div>
                </div>
              )}
            </div>
          </div>


          {error ? (
            <div className='mb-8 text-center'>
              <span className="badge badge-light-danger fs-5">{error}</span>
            </div>
          ) : <></>}


          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms || !formik.values.acceptPrivacy || loading}
            >
              {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.REGISTER.SUBMIT'})}</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </>
      ) : (
        <div className='my-8 text-center'>
          <span className="badge badge-light-success fs-5">{success}</span>
        </div>
      )}


    </form>
  )
}
