/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {login} from '../../../helpers/api_helper'
import {useIntl} from 'react-intl'


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const {setUser} = useAuth()

  const intl = useIntl()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'WRONG_EMAIL_FORMAT'}))
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'EMAIL.REQ'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'MIN_3_SYM'}))
      .max(50, intl.formatMessage({id: 'MAX_50_SYM'}))
      .required(intl.formatMessage({id: 'PWD.REQ'})),
  })
  
  const initialValues = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let response: any;
      try {
        response = await login({
          email: values.email,
          password: values.password
        })
      }
      catch (error: any) {
        response = error?.response
        setError(response?.data?.msg)
      }
      setLoading(false)

      if (response?.status == 200) {
        window.location.href = './dashboard'
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}</h1>
      </div>

      {error ? (
        <span className="badge badge-light-danger mb-8">{error}</span>
      ) : <></>}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'AUTH.EMAIL'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.EMAIL'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: 'AUTH.PASSWORD'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.PASSWORD'})}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}

        <div className='mt-3'>
          <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bolder'
          >
            {intl.formatMessage({id: 'AUTH.FORGOT_PASSWORD'})}
          </Link>
        </div>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      <div className='d-flex align-items-center mt-4'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      <div className='text-gray-400 fw-bold fs-4 text-center mt-4'>
        <Link to='/auth/registration' className='link-primary fw-bolder'>
          {intl.formatMessage({id: 'AUTH.CREATE_ACCOUNT'})}
        </Link>
      </div>

    </form>
  )
}
