/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx'
import React, {FC} from 'react'
import {useAuth} from '../modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {HeaderNotificationsMenu, QuickLinks, Search} from '../../_metronic/partials'
import {HeaderUserMenu} from './HeaderUserMenu'
// import {useLayout} from '../../core'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  // const {config} = useLayout()

  const { user } = useAuth()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/*
      <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div>
      */}

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='Profile' />
          {/*
          <span className='symbol-label bg-light-info'>
            <KTSVG
              path='/media/icons/duotune/finance/fin006.svg'
              className='svg-icon-2x svg-icon-info'
            />
          </span>
          */}
          <h6 className="mb-0 ms-3">{user?.name}</h6>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Topbar}
