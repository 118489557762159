/* eslint-disable @typescript-eslint/no-unused-vars */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {HeaderWrapper} from '../../_metronic/layout/components/header/HeaderWrapper'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../../_metronic/partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import {Languages} from '../navigation/Languages'
import {useLang, setLanguage} from '../../_metronic/i18n/Metronici18n'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'jp',
    name: '日本語',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  }
]


const PublicLayout = () => {
  const location = useLocation()
  const lang = useLang()
  const currentLanguage = (languages.find((x) => x.lang === (lang == 'en' ? 'jp' : 'en')) || {}) as any

  return (
    <>
      <div className='d-flex justify-content-end align-items-stretch flex-shrink-0 mt-5 me-5'>
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-2 fs-6 show'
            style={{ width: '150px' }}
          >
            <div
              className='menu-item px-3'
              key={currentLanguage.lang}
              onClick={() => {
                setLanguage(currentLanguage.lang)
              }}
            >
              <div
                className='menu-link d-flex px-5'
              >
                <span className='symbol symbol-20px me-4'>
                  <img className='rounded-1' src={currentLanguage.flag} alt='metronic' />
                </span>
                {currentLanguage.name}
              </div>
            </div>
          </div>
      </div>


      <Outlet />
    </>
  )
}

export {PublicLayout}
