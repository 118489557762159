import React, {useEffect,useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {api} from '../../helpers/api_helper'


export function TermsOfService() {
  const [termsContent, setTermsContent] = useState<string | null>(null)



  return (
    <div className='card w-800px mx-auto mt-5'>
      <div className='card-body'>

        <div className='text-left mb-10'>
          <Link to='/auth/login'>
              <div className='d-flex align-items-center fs-5 fw-bolder'>
                <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2 ms-0 text-primary' />
                <span>Back to Login</span>
              </div>
          </Link>
        </div>

        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Terms of Service</h1>
          <p className='text-gray-400 fw-bold'>Below are the terms of service.</p>
          {/* <div className='d-flex flex-column align-items-center p-10 border border-light'>
          <h2>一般廃棄物収集運搬業務委託契約書</h2> */}
          <p className='d-inline-block fs-6' style={{ whiteSpace: 'pre' }}>{ termsContent }</p>
        {/* </div> */}
        </div>
      </div>
    </div>
  )
}
