import React from "react";
import { useIntl } from 'react-intl'



export default function TermsData({ type }) {

  const intl = useIntl()

  const t = (id) => {
    return intl.formatMessage({ id })
  }



  return (
    <div className="terms-content">
      <h2 className="legal-title">{t('GUIDELINE.TERMS_OF_USE')}</h2>

      <span className="legal-text-formatted">{`
この規約は、お客様が(以下「利用者」)、株式会社ビートルマネージメント（以下「当社」）が 提供する「DUSTALK」（ダストーク）サービス（以下「本サービス」）をご利用頂く際の取扱 いにつき定めるものです。本規約に同意した上で本サービスをご利用ください

第１条（定義）
本規約上で使用する用語の定義は、次に掲げるとおりとします。
（1）本サービス　当社が運営するサービス及び関連するサービス
（2）本サイト　　本サービスのコンテンツが掲載されたウェブサイト

第２条（本規約への同意）
１　利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします。
２　利用者が、本サービスをスマートフォンその他の情報端末でLINE友達追加し、本規約への同意手続を行った時点で、利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。
３　利用者が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。
４　未成年者の利用者が、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことは出来ません。

第３条（規約の変更）
１　当社は、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。
２　当社は、本規約を改定するときは、その内容について当社所定の方法により利用者に通知します。

第４条(サービスの変更、追加、停止)
当社は、利用者に事前の通知をすることなく、本サービスの内容の全部又は一部を変更、追加又は停止する場合があり、利用者はこれをあらかじめ承諾するものとします。

第５条（アカウントの管理）
１　利用者は、利用に際して登録した情報（以下、「登録情報」）について、自己の責任の下、任意に登録、管理するものとします。利用者は、これを第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならないものとします。
２　当社は、登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。

第６条（個人情報等の取り扱い）
個人情報及び利用者情報については、当社が別途定める「プライバシーポリシー」に則り、適正に取り扱うこととします。

第７条（禁止行為）
１　利用者は、次の行為を行うことはできません。
(1)本サービスの運営を妨げ、又はそのおそれのある行為
(2)他の利用者による本サービスの利用を妨害する行為
(3)本サービスにかかる著作権その他の権利を侵害する行為
(4)当社、他の利用者又は第三者の権利又は利益（名誉権、プライバシー権及び著作権を含みますが、これらに限られません。）を侵害する行為
(5)公序良俗その他法令に違反する行為及びこれに違反する恐れのある行為
(6)本規約に違反する行為
(7)前各号の他、本サービスの趣旨に鑑みて当社が不適切と判断する行為
２  利用者が前項に定める行為を行ったと当社が判断した場合、当社は、利用者に事前に通知することなく、本サービスの全部又は一部の利用停止その他当社が必要かつ適切と判断する措置を講じることができます。本項の措置により利用者に生じる損害又は不利益について当社は、一切の責任を負わないものとします。

第８条（反社会的勢力の排除）
利用者は、当社に対し、次の事項を確約します。
(1)自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下総称して「反社会的勢力」といいます。）ではないこと。
(2) 反社会的勢力に自己の名義を利用させ、本契約を締結するものでないこと。
(3) 自ら又は第三者を利用して、次の行為をしないこと。
①相手方に対する脅迫的な言動又は暴力を用いる行為
②法的な責任を超えた不当な要求行為
③偽計又は威力を用いて相手方の業務を妨害し、又は信用を毀損する行為

第９条（免責）
１  天災地変、戦争、テロ行為、暴動、労働争議、伝染病、法令の制定改廃、政府機関の介入その他不可抗力により、本サービスの全部又は一部の停止、中断、遅延が発生した場合、当社は、利用者に生じた損害又は不利益について一切責任を負いません。
２　利用者は、通信回線やコンピュータの障害、システムメンテナンスその他の事由による本サービスの全部又は一部の停止、中断、遅延が起こり得ることを理解しているものとし、当社は、これらにより利用者に生じた損害又は不利益について一切責任を負いません。また、利用者の利用環境によって生じた損害又は不利益について、当社は一切責任を負いません。
３　当社は、理由の如何を問わず、データ等の全部又は一部が滅失、毀損、又は改ざんされた場合いに、これを復元する義務を負わないものとし、当該滅失、毀損、又は改ざんにより利用者又は第三者に生じた損害等について一切の責任を負わないものとします。

第１０条（広告の掲載について）
利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。

第１１条（当社への連絡方法）
本サービスに関する利用者の当社へのご連絡・お問い合わせは、本サイト内の適宜の場所に設置するお問い合わせフォームからの送信または当社が別途指定する方法により行うものとします。

第１２条（準拠法、管轄裁判所）
１　本規約の有効性，解釈及び履行については，日本法に準拠し，日本法に従って解釈されるものとします。
２　当社と利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、福岡地方裁判所　小倉支部を専属的合意管轄裁判所とします。2023年4月26日　施行
      `}</span>
    </div>
  )
}
