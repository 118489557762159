import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useParams, Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {api, setToken} from '../../helpers/api_helper'


export function QuotationDetails() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [quote, setQuote] = useState<any>(null)
  const [contractAgree, setContractAgree] = useState(false)
  const [privacyAgree, setPrivacyAgree] = useState(false)

  const { id, token } = useParams()
  const intl = useIntl()
  const lang = intl?.locale || 'en'

  const getData = async () => {
    setLoading(true)
    let response: any;
    try {
      response = await api('POST', '/quote/details', {
        quote_id: id
      })

      const quotation = response?.data?.data
      const isAccepted = quotation?.status > 0
      const isCash = quotation?.payment_mode == 'cash'

      if (isAccepted) {
        if (isCash) {
          window.location.href = '/payment-success/cash'
        }
        else {
          window.location.href = '/payment-success'
        }
      }
    }
    catch (error: any) {
      setError(error?.response?.data?.msg || null)
    }
    if (response?.status == 200) {
      setQuote(response.data.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (token) {
      setToken(token)
    }
    getData()
  }, [])

  const formatDate = (d: string | undefined, pref: string) => {
    const date = new Date(d || Date.now())
    return `${date.getFullYear()}年 ${date.getMonth() + 1}月 ${date.getDate()}日, ` + pref
  }

  const formatPrice = (amount: string) => {
    if (isNaN(parseFloat(amount))) {
      return '(No Price)'
    }
    return `¥${parseFloat(amount).toLocaleString()}`
  }

  const items = (products: any) => {
    return products?.length ? products.map(product => {
      return {
        name: (product.product_id?.name?.[lang] || '--'),
        quantity: product.quantity || 0
      }
    }) : []
  }

  const subscriptionItems = (products: any) => {
    return products?.length ? products.map(item => {
      return {
        name: (item.name?.[lang] || '--')
      }
    }) : []
  }

  const isSubscription = quote?.request_id?.request_type == 'subscription'


  return (
    <div className='mx-5'>
      <div className='card mw-500px w-100 mx-auto mt-10'>
        <div className='card-body'>

          <div className='text-center mb-10'>
            <h1 className='text-dark mt-5 mb-10'>{intl.formatMessage({id: 'INVOICE.TITLE'})}</h1>

            {loading ? (
              <span className='indicator-progress text-center m-5 pb-5' style={{display: 'block'}}>
                <span className='spinner-border text-primary align-middle ms-2'></span>
              </span>
            ) : (quote ? (
              <div className='border border-light-success rounded'>
                <p className='text-gray-400 fw-bold mt-10 mb-1'>{intl.formatMessage({id: 'COMMON.DATE_TIME'})}</p>
                <p className='fw-bold fs-4 mb-0'>{formatDate(quote?.time_preference?.date, '13:00-16:00')}</p>

                <p className='text-gray-400 fw-bold mt-10 mb-1'>{intl.formatMessage({id: 'COMMON.COMPANY_NAME'})}</p>
                <p className='fw-bold fs-4 mb-0'>{quote?.company_id?.name || '(No Company)'}</p>

                <p className='text-gray-400 fw-bold mt-10 mb-1'>{intl.formatMessage({id: 'REQUESTS.ITEMS'})}</p>
                {isSubscription ? subscriptionItems(quote?.request_id?.trash_type).map((item, index) => {
                  return (
                    <div className='d-flex justify-content-center align-items-center px-10' key={index}>
                      <p className='fw-bold fs-4 mb-0'>{item.name}</p>
                    </div>
                  )
                }) : items(quote?.request_id?.products).map((item, index) => {
                  return (
                    <div className='d-flex justify-content-between align-items-center px-10' key={index}>
                      <p className='fw-bold fs-4 mb-0'>{item.name}</p>
                      <p className='fw-bold fs-4 mb-0'>{item.quantity}</p>
                    </div>
                  )
                })}

                {isSubscription && (
                  <>
                    <p className='text-gray-400 fw-bold mt-10 mb-1'>{intl.formatMessage({id: 'REQUESTS.NUM_BAGS'})}</p>
                    <p className='fw-bold fs-4 mb-0'>{quote?.request_id?.details?.no_of_bags || '--'}</p>
                  </>
                )}

                <p className='text-gray-400 fw-bold mt-10 mb-1'>{intl.formatMessage({id: 'COMMON.AMOUNT'})}</p>
                <h2 className='fw-bold fs-1 mb-10 text-success'>{formatPrice(quote?.details?.price)}</h2>
              </div>
            ) : (
              <p className='text-danger'>{error}</p>
            ))}

          </div>


          {quote ? (
            <div className='d-flex flex-column align-items-center'>

              <div className='d-flex align-items-center w-100 mb-5'>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={() => setContractAgree(!contractAgree)}
                  />
                </div>
                <p className='fs-5 mb-0 ms-4'><Link
                  to="/contract-agreement"
                  target="_blank"
                  className="text-primary"
                >{intl.formatMessage({id: 'INVOICE.ACCEPT_CONTRACT_LINK'})}</Link></p>
              </div>

              <div className='d-flex align-items-center w-100 mb-10'>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={() => setPrivacyAgree(!privacyAgree)}
                  />
                </div>
                <p className='fs-5 mb-0 ms-4'><Link
                  to="/privacy-agreement"
                  target="_blank"
                  className="text-primary"
                >{intl.formatMessage({id: 'INVOICE.AGREE_PRIVACY_LINK'})}</Link></p>
              </div>




              <Link to={`/payment-method/${id}/${token}${isSubscription ? '/subscription' : ''}`}
                className={`btn btn-sm btn-primary ${contractAgree && privacyAgree ? '' : 'disabled'}`}
              >{intl.formatMessage({id: 'INVOICE.ACCEPT'})}</Link>

            </div>
          ) : <></>}

        </div>
      </div>
    </div>
  )
}
