import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'


export function PrivacyPolicy() {


  return (
    <div className='card w-800px mx-auto mt-5'>
      <div className='card-body'>

        <div className='text-left mb-10'>
          <Link to='/auth/login'>
              <div className='d-flex align-items-center fs-5 fw-bolder'>
                <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2 ms-0 text-primary' />
                <span>Back to Login</span>
              </div>
          </Link>
        </div>

        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Privacy Policy</h1>
          <p className='text-gray-400 fw-bold'>Below is the privacy policy.</p>
        </div>
      </div>
    </div>
  )
}
