import React from 'react'
import {useNavigate} from 'react-router-dom'
import PrivacyData from './PrivacyData'


export function PrivacyAgreement() {

  const navigate = useNavigate()


  return (
    <div>
       <div className="row">
         {/* <h1 className="slogan small">プライバシーポリシー</h1> */}
       </div>
   
       <div className="page-content my-page-content w-25 mx-auto">
         <br/>
   
          <PrivacyData type={undefined}/>

         <br/>
   
         <div className="button-column">
         <button
        className='btn btn-sm btn-light align-items-center ms-auto'
        onClick={() => navigate(-1)}
      >
        戻る
      </button>
         </div>
   
         <br/>
   
       </div>
  </div>
  
  )
}
