import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {api} from '../../helpers/api_helper'


export function PaymentFailure() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>('Error: Something went wrong.')
  const [success, setSuccess] = useState<string | null>(null)

  return (
    <>
      <div className='card w-100 mw-400px mx-auto mt-20'>
        <div className='card-body d-flex flex-column'>
          <div className='text-center mt-5'>
            <h1 className='text-danger mb-3'>Payment Failed!</h1>
            <p className='badge badge-light-danger fw-bold mt-10'>{error}</p>
          </div>
        </div>
      </div>
    </>
  )
}
