/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import { useNavigate, Link } from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useAuth} from '../../modules/auth/core/Auth'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {MixedWidget11} from './MixedWidget11'
import {DashboardWidget1} from './DashboardWidget1'
import {DashboardWidget2} from './DashboardWidget2'

import {api} from '../../helpers/api_helper'
import {formatStatus} from '../utility'
import { DashboardWidget3 } from './DashboardWidget3'
import { addCommas } from '../utility'


const Dashboard: FC = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>(null)
  const [currentMonth, setCurrentMonth] = useState<any>();
  const [currentMonthNumber, setCurrentMonthNumber] = useState<any>();
  const [currentYear, setCurrentYear] = useState<any>();
  const [dates , setDates] = useState<any>([])
  const [sales , setSales] = useState<any>([])
  const [totalSaleAmount , setTotalSaleAmount] = useState<any>()

  const [latestSpotRequests, setLatestSpotRequests] = useState<any>([])
  const [latestSubscriptionRequests, setLatestSubscriptionRequests] = useState<any>([])
  const [error, setError] = useState<string | null>(null)
  const intl = useIntl()
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const date = new Date();
    const monthName = date.toLocaleString('default', { month: 'long' });
    setCurrentMonth(monthName);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    setCurrentMonthNumber(month.toString());
    setCurrentYear(year.toString());
  }, []);

  const getData = async () => {
    let response: any;
    try {
      response = await api('GET', '/reports/dashboard')
    }
    catch (error: any) {
      setError(error?.response?.data?.msg || null)
    }
    if (response?.status == 200) {
      setData(response.data?.data)
    }
  }

  const getLatestSpotRequests = async () => {
    let response: any;
    try {
      response = await api('POST', '/service/request/list', {
        request_type: 'spot',
        skip: 0,
        limit: 3
      })
    }
    catch (error: any) {
      setError(error?.response?.data?.msg || null)
    }
    if (response?.status == 200) {
      setLatestSpotRequests(response.data?.data?.requests || [])
    }
  }

  const getLatestSubscriptionRequests = async () => {
    let response: any;
    try {
      response = await api('POST', '/service/request/list', {
        request_type: 'subscription',
        skip: 0,
        limit: 3
      })
    }
    catch (error: any) {
      setError(error?.response?.data?.msg || null)
    }
    if (response?.status == 200) {
      setLatestSubscriptionRequests(response.data?.data?.requests || [])
    }
  }

  const init = async () => {
    setLoading(true)

    const result = await Promise.allSettled([
      getData(), getLatestSpotRequests(), getLatestSubscriptionRequests()
    ])

    setLoading(false)
  }

  useEffect(() => {
    init()
  }, [])

  // const categoryNames = Object.values(categories).reduce((total, current) => {
  //   return { ...total, [current._id]: typeof current.name == 'object' ? current.name[lang] : current.name }
  // }, {})

  const formatDate = (d: string | undefined) => {
    const date = new Date(d || Date.now())
    return `${date.getFullYear()}年 ${date.getMonth() + 1}月 ${date.getDate()}日, ${date.toLocaleString('en-US', {
      timeStyle: 'short',
      hourCycle: 'h24'
    })}`
  }

  const formatCategories = (categories: Array<any>) => {
    // const string = categories.map(categoryID => categoryNames[categoryID]).join(', ')
    // return string?.length > 35 ? string.substring(0, 35) + '...' : string
    return ''
  }

  const statusClass = (request: any) => {
    switch (request?.status) {
      case 1:
        if (request?.pickup?.length) {
          return 'light-info'
        }
        else {
          return 'light-success'
        }
      case 2:
        return 'light'
      case 3:
        return 'light-danger'
      case 4:
        return 'light-danger'
      default:
        return 'light-warning'
    }
  }

  const statusText = (request: any) => {
    switch (request?.status) {
      case 1:
        if (request?.pickup?.length) {
          return 'collected'
        }
        else {
          return 'accepted'
        }
      case 2:
        return 'complete'
      case 3:
        return 'cancelled'
      case 4:
        return 'cancelled'
      default:
        return 'open'
    }
  }

  const goToRequestDetails = (id: string | number) => {
    if (!id) return;
    navigate('/request/' + id)
  }

  const lang = intl?.locale || 'en'
  const isAdmin = user?.role === 'admin'

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row gy-5 g-xl-8'>
      <div className='row g-5 g-xl-8 justify-content-center align-items-stretch'>
         <h1>{intl.formatMessage({id: 'CURRENT_MONTH_STATS'})}</h1>

          {isAdmin && (
            <div className='col-md-3 col-xs-6 d-flex flex-column'>
              <DashboardWidget3
                className='card-xl-stretch mb-xl-8 align-self-stretch'
                svgIcon='/media/icons/duotune/general/gen032.svg'
                color='white'
                iconColor='primary'
                activeCompanies={data?.companies_registered?.activated || 0}
                registeredCompanies={data?.companies_registered?.registered || 0}
                description={intl.formatMessage({id: 'DASHBOARD.NO_OF_ACTIVE_COMPANIES'})}
                onClick={() => navigate('/companies')}
              />
            </div>
          )}
          <div className='col-md-3 col-xs-6 d-flex flex-column align-self-stretch'>
            <DashboardWidget2
              className='card-xl-stretch mb-xl-8 align-self-stretch'
              svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
              color='dark'
              iconColor='white'
              title={data?.requests_processed}
              description={intl.formatMessage({id: 'DASHBOARD.NO_OF_REQUESTS'})}
              onClick={() => navigate('/quotations')}
            />
          </div>

          <div className='col-md-3 col-xs-6 d-flex flex-column align-self-stretch'>
            <DashboardWidget2
              className='card-xl-stretch mb-xl-8 align-self-stretch'
              svgIcon='/media/icons/duotune/graphs/gra007.svg'
              color='warning'
              iconColor='white'
              title={`¥${addCommas(Math.round(data?.sales_amount))}`}
              description={intl.formatMessage({id: 'DASHBOARD.COMPANY_SALES'})}
              onClick={() => navigate('/quotations')}
            />
          </div>

          <div className='col-md-3 col-xs-6 d-flex flex-column align-self-stretch'>
            <DashboardWidget2
              className='card-xl-stretch mb-5 mb-xl-8 align-self-stretch'
              svgIcon='/media/icons/duotune/finance/fin006.svg'
              color='info'
              iconColor='white'
              title={data?.quotes_sent}
              description={intl.formatMessage({id: 'DASHBOARD.NO_OF_QUOTES_SENT'})}
              // onClick={() => navigate('/users')}
            />
          </div>
        </div>

        {!isAdmin ? (
          <>
            <div className='col-xxl-6'>
              <div className='card'>
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'DASHBOARD.LATEST_SPOT'})}</span>
                    {error ? (
                      <span className="badge badge-light-danger mt-5">{error}</span>
                    ) : <></>}
                  </h3>
                </div>

                <div className='card-body py-3'>
                  {loading ? (
                    <span className='indicator-progress text-center m-5' style={{display: 'block'}}>
                      <span className='spinner-border text-primary align-middle ms-2'></span>
                    </span>
                  ) : (
                    <div className='table-responsive'>
                      <table className='table align-middle gs-0 gy-3'>
                        <thead>
                          <tr className='fw-bolder text-muted bg-light'>
                            <th className='ps-4 min-w-200px rounded-start text-nowrap'>{intl.formatMessage({id: 'COMMON.REQUEST'})}</th>
                            <th className='min-w-100px text-nowrap'>{intl.formatMessage({id: 'COMMON.LOCATION'})}</th>
                            <th className='min-w-150px text-nowrap'>{intl.formatMessage({id: 'COMMON.MY_QUOTE'})}</th>
                            <th className='min-w-150px text-nowrap'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                            <th className='min-w-100px text-nowrap'>{intl.formatMessage({id: 'COMMON.PAYMENT'})}</th>
                            <th className='min-w-10px text-end rounded-end text-nowrap'>{intl.formatMessage({id: 'COMMON.ACTIONS'})}</th>
                          </tr>
                        </thead>

                        {latestSpotRequests?.length ? (
                          <tbody>
                            {latestSpotRequests.map(request => {
                              return (
                                <tr key={request._id}>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-50px me-5'>
                                        <span className='symbol-label bg-light-grey'>
                                          <KTSVG
                                            path='/media/icons/duotune/abstract/abs042.svg'
                                            className='svg-icon-2x svg-icon-grey'
                                          />
                                        </span>
                                      </div>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6 text-nowrap'>
                                          {request?.details?.nameKanji || '(No Name)'}
                                        </a>
                                        <span className='text-muted fw-bold text-muted d-block fs-7 text-nowrap'>
                                          {formatDate(request.created_at) || '--'}
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <p className='text-dark fw-bolder d-block mb-1 fs-6 text-nowrap text-capitalize'>
                                      {request.details?.prefecture + " "+ request?.details?.city+" "+ request?.details?.street_address  || '--'}
                                    </p>
                                    <span className='text-muted fw-bold text-muted d-block fs-7 text-nowrap text-capitalize'>{request.details?.postalCode || '--'}</span>
                                  </td>

                                  <td>
                                    {request.quotes?.length ? (
                                      <p className='text-dark fw-bolder d-block mb-1 fs-6 text-nowrap'>
                                        ¥{addCommas(Math.round(request.quotes[request.quotes.length - 1]?.initial_payment_amount))}
                                      </p>
                                    ) : (
                                      <span className={`badge badge-light-danger fw-bold me-1 text-capitalize`}>{intl.formatMessage({id: 'REQUESTS.NO_QUOTE_GIVEN'})}</span>
                                    )}
                                  </td>

                                  <td>
                                    <span className={`badge badge-${formatStatus(request, intl).color} fw-bold me-1 text-capitalize`}>{formatStatus(request, intl).text}</span>
                                  </td>

                                  <td className=''>
                                    <span className={`badge badge-light-${request.payment ? 'success' : 'danger'} fw-bold me-1 text-capitalize`}>{request.payment ? intl.formatMessage({id: 'COMMON.PAYMENT.PAID'}) : intl.formatMessage({id: 'COMMON.PAYMENT.NOT_PAID'})}</span>
                                  </td>

                                  <td className='text-end'>
                                    <a onClick={() => goToRequestDetails(request._id)} className='btn btn-sm btn-bg-light btn-active-color-primary'>
                                      <div className="d-flex align-items-center">
                                        <span className="text-nowrap">{intl.formatMessage({id: 'COMMON.VIEW_DETAILS'})}</span>
                                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2 ms-2 me-0' />
                                      </div>
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        ) : <></>}
                      </table>

                      {!latestSpotRequests?.length ? (
                        <p className='text-muted text-center'>{intl.formatMessage({id: 'REQUESTS.NO_REQUESTS'})}</p>
                      ) : <></>}
                    </div>
                  )}
                </div>
              </div>
            </div>



            <div className='col-xxl-6'>
              <div className='card'>
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({id: 'DASHBOARD.LATEST_SUBSCRIPTION'})}</span>
                    {error ? (
                      <span className="badge badge-light-danger mt-5">{error}</span>
                    ) : <></>}
                  </h3>
                </div>

                <div className='card-body py-3'>
                  {loading ? (
                    <span className='indicator-progress text-center m-5' style={{display: 'block'}}>
                      <span className='spinner-border text-primary align-middle ms-2'></span>
                    </span>
                  ) : (
                    <div className='table-responsive'>
                      <table className='table align-middle gs-0 gy-3'>
                        <thead>
                          <tr className='fw-bolder text-muted bg-light'>
                            <th className='ps-4 min-w-200px rounded-start text-nowrap'>{intl.formatMessage({id: 'COMMON.REQUEST'})}</th>
                            <th className='min-w-100px text-nowrap'>{intl.formatMessage({id: 'COMMON.LOCATION'})}</th>
                            <th className='min-w-150px text-nowrap'>{intl.formatMessage({id: 'COMMON.MY_QUOTE'})}</th>
                            <th className='min-w-150px text-nowrap'>{intl.formatMessage({id: 'COMMON.STATUS'})}</th>
                            <th className='min-w-100px text-nowrap'>{intl.formatMessage({id: 'COMMON.PAYMENT'})}</th>
                            <th className='min-w-10px text-end rounded-end text-nowrap'>{intl.formatMessage({id: 'COMMON.ACTIONS'})}</th>
                          </tr>
                        </thead>

                        {latestSubscriptionRequests?.length ? (
                          <tbody>
                            {latestSubscriptionRequests.map(request => {
                              return (
                                <tr key={request._id}>
                                  <td>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-50px me-5'>
                                        <span className='symbol-label bg-light-grey'>
                                          <KTSVG
                                            path='/media/icons/duotune/abstract/abs042.svg'
                                            className='svg-icon-2x svg-icon-grey'
                                          />
                                        </span>
                                      </div>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6 text-nowrap'>
                                          {request?.details?.nameKanji || '(No Name)'}
                                        </a>
                                        <span className='text-muted fw-bold text-muted d-block fs-7 text-nowrap'>
                                          {formatDate(request.created_at) || '--'}
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <p className='text-dark fw-bolder d-block mb-1 fs-6 text-nowrap text-capitalize'>
                                      {request.details?.prefecture + " "+ request?.details?.city+" "+ request?.details?.street_address  || '--'}
                                    </p>
                                    <span className='text-muted fw-bold text-muted d-block fs-7 text-nowrap text-capitalize'>{request.details?.postalCode || '--'}</span>
                                  </td>

                                  <td>
                                    {request.quotes?.length ? (
                                      <p className='text-dark fw-bolder d-block mb-1 fs-6 text-nowrap'>
                                        ¥{addCommas(Math.round(request.quotes[request.quotes.length - 1]?.initial_payment_amount))}
                                      </p>
                                    ) : (
                                      <span className={`badge badge-light-danger fw-bold me-1 text-capitalize`}>{intl.formatMessage({id: 'REQUESTS.NO_QUOTE_GIVEN'})}</span>
                                    )}
                                  </td>

                                  <td>
                                    <span className={`badge badge-${formatStatus(request, intl).color} fw-bold me-1 text-capitalize`}>{formatStatus(request, intl).text}</span>
                                  </td>

                                  <td className=''>
                                    <span className={`badge badge-light-${request.payment ? 'success' : 'danger'} fw-bold me-1 text-capitalize`}>{request.payment ? intl.formatMessage({id: 'COMMON.PAYMENT.PAID'}) : intl.formatMessage({id: 'COMMON.PAYMENT.NOT_PAID'})}</span>
                                  </td>

                                  <td className='text-end'>
                                    <a onClick={() => goToRequestDetails(request._id)} className='btn btn-sm btn-bg-light btn-active-color-primary'>
                                      <div className="d-flex align-items-center">
                                        <span className="text-nowrap">{intl.formatMessage({id: 'COMMON.VIEW_DETAILS'})}</span>
                                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2 ms-2 me-0' />
                                      </div>
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        ) : <></>}
                      </table>

                      {!latestSubscriptionRequests?.length ? (
                        <p className='text-muted text-center'>{intl.formatMessage({id: 'REQUESTS.NO_REQUESTS'})}</p>
                      ) : <></>}
                    </div>
                  )}
                </div>
              </div>
            </div>

          </>
        ) : <></>}

        <div className='col-xxl-6'>
          <DashboardWidget1
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
            dates = {data?.day_wise_line_chart_data?.dates}
            spot = {data?.day_wise_line_chart_data?.spot}
            sub = {data?.day_wise_line_chart_data?.sub}
            quotes = {data?.day_wise_line_chart_data?.quotes}
            month = {currentMonthNumber}
            year = {currentYear}
          />
        </div>

        {/*
          <div className='col-xxl-4'>
          <ListsWidget5 className='card-xxl-stretch' />
          </div>
        */}
        <div className='col-xxl-6'>
          <MixedWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
            currentMonth = {currentMonth}
            dates = {data?.current_month_sales?.dates}
            sales={data?.current_month_sales?.sales}
            totalSaleAmount = {addCommas(Math.round(data?.sales_amount))}
          />
        </div>

        {/*
        <div className='row g-5 gx-xxl-8'>
          <div className='col-xxl-12'>
            <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
          </div>
        </div>
        */}

      </div>
    </>
  )
}

export {Dashboard}
