/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {useIntl} from 'react-intl'
import { addCommas } from '../utility'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  currentMonth : string,
  dates: Array<number>; 
  sales: Array<number>;
  totalSaleAmount : string
}

const MixedWidget11: React.FC<Props> = ({className, chartColor, chartHeight , currentMonth , dates, sales, totalSaleAmount}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()

  useEffect(() => {
    if (!chartRef.current || !dates || !sales) {
      return;
    }
  
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, dates, sales));
    chart.render();
  
    return () => {
      chart.destroy();
    };
  }, [chartRef, chartColor, chartHeight, dates, sales]);
  


  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0 d-flex justify-content-between flex-column'  style={{background:'white'}}>
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>{intl.formatMessage({id: 'DASHBOARD.SALES'})}</span>

            <span className='text-gray-400 fw-bold'>{currentMonth}</span>
          </div>

          <div className={`fw-bolder fs-3 text-${chartColor}`}>¥{totalSaleAmount}</div>
        </div>
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string , dates : Array<number> , sales : Array<number>): ApexOptions => {

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: sales,
      },
      // {
      //   name: 'Revenue',
      //   data: [50, 60, 70, 80, 60, 50, 70, 60],
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      categories: dates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    // yaxis: {
    //   labels: {
    //     style: {
    //       colors: labelColor,
    //       fontSize: '12px',
    //     },
    //   },
    // },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (value: number) {
          return '¥' + addCommas(Math.round(value));
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '¥' + addCommas(Math.round(val)) + ' 収益'
        },
      },
    },
    colors: [baseColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      // yaxis: {
      //   lines: {
      //     show: true,
      //   },
      // },
    },
  }
}

export {MixedWidget11}
