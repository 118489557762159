import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {api} from '../../../helpers/api_helper'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match"),
    }),
})

export function ResetPassword() {
  const [validateComplete, setValidateComplete] = useState(false)
  const [validateError, setValidateError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const { key } = useParams()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setError(null)
      let response: any;
      try {
        response = await api('put', '/users/setPassword', {
          password: values.password,
          key: key,
          email: 'admin@bitbaazi.com'
        })
      }
      catch (error: any) {
        response = error?.response
      }

      if (response?.status == 200) {
        setSuccess(response?.data?.msg)
        // setSuccess('Please check your email for a link to reset your password.')
      }
      else {
        setError(response?.data?.msg || 'Unknown Error: Something went wrong')
      }

      setLoading(false)
    },
  })

  const validateKey = async () => {
    let response: any;
    try {
      response = await api('GET', '/users/validateKey/' + key)
    }
    catch (error: any) {
      response = error?.response
    }

    if (response?.status != 200) {
      setValidateError(response?.data?.data || 'Unknown Error: Something went wrong')
    }

    setValidateComplete(true)
    PasswordMeterComponent.bootstrap()
  }

  useEffect(() => {
    validateKey()
  }, [])



  return !validateComplete ? (
    <p>Validating...</p>
  ) : (validateError ? (
    <div className='mb-8 text-center'>
      <span className="badge badge-light-danger fs-5">{validateError}</span>
    </div>
  ) : (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-left mb-10'>
        <Link to='/auth/login'>
            <div className='d-flex align-items-center fs-5 fw-bolder'>
              <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-2 ms-0 text-primary' />
              <span>Back to Login</span>
            </div>
        </Link>
      </div>

      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Reset Password</h1>
        <p className='text-gray-400 fw-bold'>Enter a new password below.</p>
      </div>

      {error ? (
        <div className='mb-8 text-center'>
          <span className="badge badge-light-danger fs-5">{error}</span>
        </div>
      ) : <></>}

      {success ? (
        <div className='mb-8 text-center'>
          <span className="badge badge-light-success fs-5">{success}</span>
        </div>
      ) : (
        <>
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Password</label>
              <div className='position-relative mb-3'>
                <input
                  type='password'
                  placeholder='New Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
            </div>
            <div className='text-muted'>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </div>
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <input
              type='password'
              placeholder='Confirm New Password'
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </>
      )}

    </form>
  ))
}
