/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../../_metronic/layout/core'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {MetronicI18nProvider} from '../../_metronic/i18n/Metronici18n';
import {TermsOfService} from '../pages/legal/TermsOfService';
import {PrivacyPolicy} from '../pages/legal/PrivacyPolicy';

import {ContractAgreement} from '../pages/mobile/ContractAgreement';
import {PrivacyAgreement} from '../pages/mobile/PrivacyAgreement';
import {PaymentMethod} from '../pages/mobile/PaymentMethod';
import {PaymentSuccess} from '../pages/mobile/PaymentSuccess';
import {PaymentFailure} from '../pages/mobile/PaymentFailure';
import {QuotationDetails} from '../pages/mobile/QuotationDetails';

import {PublicLayout} from './PublicLayout';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env


const AppContainer = () => (
  <Suspense fallback={<LayoutSplashScreen />}>
    <MetronicI18nProvider>
      <I18nProvider>
        <LayoutProvider>
          <Outlet />
          <MasterInit />
        </LayoutProvider>
      </I18nProvider>
    </MetronicI18nProvider>
  </Suspense>
)


const AppRoutes: FC<any> = (props: any) => {

  const { user } = props;

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<AppContainer />}>

          <Route element={<PublicLayout />}>

            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />

            <Route path='terms-of-service' element={<TermsOfService />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />

            <Route path='contract-agreement' element={<ContractAgreement />} />
            <Route path='privacy-agreement' element={<PrivacyAgreement />} />
            <Route path='quotation-details/:id/:token' element={<QuotationDetails />} />
            <Route path='payment-method/:id/:token/:type' element={<PaymentMethod />} />
            <Route path='payment-method/:id/:token' element={<PaymentMethod />} />
            <Route path='payment-success/:mode' element={<PaymentSuccess />} />
            <Route path='payment-success' element={<PaymentSuccess />} />
            <Route path='payment-failure/:mode' element={<PaymentFailure />} />
            <Route path='payment-failure' element={<PaymentFailure />} />
          </Route>

          {user ? (
            <>
              <Route path='/*' element={<PrivateRoutes user={user} />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
          <Route path='/' element={<Navigate to='/auth' />} />

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
