import {useEffect, useState} from 'react'
import {AuthProvider, AuthInit, AuthProps} from './modules/auth'
import {AppRoutes} from './routing/AppRoutes'
import {api, getToken} from './helpers/api_helper'
import {useAuth} from './modules/auth/core/Auth'



const App = () => {
  const [isUserFetched, setIsUserFetched] = useState(false)

  return (
    <AuthProvider
      isUserFetched={isUserFetched}
      setIsUserFetched={setIsUserFetched}
    >
      {isUserFetched ? <AppRoutes /> : <></>}
    </AuthProvider>
  )
}

export {App}
