/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import axios from 'axios'
import {createContext, useContext} from 'react'



const mode = 'test';


class ApiHelper {
  _mode: string;
  _token: string | undefined;

  constructor(mode: string) {
    this._mode = mode
    this._token = undefined
  }

  get url() {
    switch (this._mode) {
      case 'test':
        return 'https://dasutalk-master.ceewen.xyz:9055/'
      case 'prod':
        return 'https://api.dustalk.com/'
      default: // dev
        return 'https://81c9-2409-4070-4c8b-ad1b-c71-9ec2-b1ca-dc33.ngrok-free.app/'
    }
  }

  get token() {
    return this._token
  }

  set token(token: string | undefined) {
    this._token = token
  }
}

const apiHelper = new ApiHelper(mode)
const apiToken = getToken()
if (apiToken) {
  axios.defaults.headers.common['authorization'] = 'Bearer ' + apiToken
}



export function baseURL() {
  return apiHelper.url
}


export function getToken() {
  return window.localStorage.getItem('dasutalk_token')
}

export function setToken(token: string) {
  apiHelper.token = token
  window.localStorage.setItem('dasutalk_token', token)
  axios.defaults.headers.common['authorization'] = 'Bearer ' + token
}

export function clearToken() {
  apiHelper.token = undefined
  window.localStorage.removeItem('dasutalk_token')
  axios.defaults.headers.common['authorization'] = ''
}

export async function getCompaniesList(){
  let response :any;
  try {
    response = await axios.get(apiHelper.url + 'api/reports/companies_list')
  }
  catch (error: any) {
    response = error?.response;
    console.error(response);
    throw {
      error: new Error(),
      response
    }
  }
  return response;
}


export async function login(params: any) {
  let response;
  try {
    response = await axios.post(apiHelper.url + 'api/users/login', params)
    let token = response?.data?.data?.token
    setToken(token)
  }
  catch (error: any) {
    response = error?.response;
    console.error(response);
    throw {
      error: new Error(),
      response
    }
  }
  return response;
}

export const statusClass = (status: string | number) => {
  switch (status) {
    case 1:
      return 'light-success'
    case 2:
      return 'light'
    case 3:
      return 'light-danger'
    case 4:
      return 'light-danger'
    default:
      return 'light-warning'
  }
}

export const statusText = (status: string | number) => {
  switch (status) {
    case 1:
      return 'accepted'
    case 2:
      return 'complete'
    case 3:
      return 'cancelled'
    case 4:
      return 'cancelled'
    default:
      return 'open'
  }
}

export const formatCurrency = (currency: string) => {
  switch (currency ? currency.toLowerCase() : currency) {
    case 'dollars':
      return '$'
    case 'yen':
      return '¥'
    default:
      return ''
  }
}



export function api(method: string, endpoint: string, params?: any) {
  switch (method.toLowerCase()) {
    case 'post':
      return axios.post(apiHelper.url + 'api' + endpoint, params)
    case 'put':
      return axios.put(apiHelper.url + 'api' + endpoint, params)
    case 'delete':
      return axios.delete(apiHelper.url + 'api' + endpoint)
    default:
      return axios.get(apiHelper.url + 'api' + endpoint)
  }
}
